import React from 'react';
import styles from './icon.module.sass';

type Props = {
  light?: boolean;
};

const CheckIcon = ({ light }: Props) => {
  return (
    <svg
      className={styles.container}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <path
        className={`${styles.icon} ${light ? styles.light : ''}`}
        d="M34.038,90.7467426 C33.328,90.7467426 32.645,90.4657426 32.14,89.9607426 L0.786,58.6067426 C-0.262,57.5587426 -0.262,55.8587426 0.786,54.8097426 C1.834,53.7617426 3.534,53.7617426 4.582,54.8097426 L33.852,84.0797426 L95.248,9.97174261 C96.194,8.82974261 97.887,8.67174261 99.028,9.61774261 C100.17,10.5627426 100.328,12.2557426 99.382,13.3967426 L36.105,89.7747426 C35.623,90.3567426 34.918,90.7087426 34.164,90.7437426 C34.122,90.7457426 34.08,90.7467426 34.038,90.7467426"
      />
    </svg>
  );
};

export default CheckIcon;
