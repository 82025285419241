import gql from 'graphql-tag';

export const M_SIGNUP_BUSINESS = gql`
  mutation registerBusiness(
    $name: String!
    $ownerId: String!
    $website: String!
    $about: AboutInput!
    $categories: CategoriesInput!
    $specs: SpecsInput!
    $mainContact: MainContactInput!
    $otherInfo: OtherInfoInput!
  ) {
    registerBusiness(
      businessInput: {
        name: $name
        ownerId: $ownerId
        website: $website
        categories: $categories
        about: $about
        specs: $specs
        mainContact: $mainContact
        otherInfo: $otherInfo
      }
    )
  }
`;

export const Q_BUSINESSES_AUTOCOMPLETE = gql`
  query businessesAutocomplete($query: String!, $first: Int) {
    businessesAutocomplete(query: $query, first: $first) {
      id
      data {
        name
        slug
        isValidated
        isPublished
      }
    }
  }
`;

export const Q_BUSINESSES = gql`
  query businesses(
    $text: String!
    $sort: SortOption!
    $primaryCategories: [String!]
    $secondaryCategories: [String!]
    $locations: [LocationSearch!]
    $blackOwned: Boolean
    # $blackPartners: Boolean
    $blackFounded: Boolean
    # $blackFronted: Boolean
    $indigenousOwned: Boolean
    $majorityWomenOwned: Boolean
    # $indigenousPartners: Boolean
    # $indigenousFounded: Boolean
    # $indigenousFronted: Boolean
    # $ethicallySustainable: Boolean
    $hasFactor: Boolean
    $hasCredit: Boolean
    $hasInvestors: Boolean
    $wantsInvestors: Boolean
    # $revenue: NumRange
    $first: Int
    $cursor: String
  ) {
    businesses(
      after: $cursor
      filters: {
        text: $text
        sort: $sort
        filters: {
          locations: $locations
          primaryCategories: $primaryCategories
          secondaryCategories: $secondaryCategories
          locations: $locations
          blackOwned: $blackOwned
          # blackPartners: $blackPartners
          blackFounded: $blackFounded
          # blackFronted: $blackFronted
          indigenousOwned: $indigenousOwned
          majorityWomenOwned: $majorityWomenOwned
          # indigenousPartners: $indigenousPartners
          # indigenousFounded: $indigenousFounded
          # indigenousFronted: $indigenousFronted
          # ethicallySustainable: $ethicallySustainable
          hasFactor: $hasFactor
          hasCredit: $hasCredit
          hasInvestors: $hasInvestors
          wantsInvestors: $wantsInvestors
          # revenue: $revenue
        }
      }
      first: $first
    ) {
      edges {
        node {
          id
          data {
            name
            slug
            isValidated
            isPublished
            headerImage
            about {
              moodImages {
                position
                image
              }
            }
            specs {
              ownership {
                blackOwned
                indigenousOwned
                majorityWomenOwned
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const Q_BUSINESS_BASE = gql`
  query business($slug: String!) {
    business(slug: $slug) {
      id
      data {
        ownerId
        isValidated
        isPublished
        name
      }
    }
  }
`;

export const Q_BUSINESS_REGISTER = gql`
  query business($slug: String!) {
    business(slug: $slug) {
      id
      data {
        name
        ownerId
        website
        otherInfo {
          intakeQuestion {
            value
            label
          }
          intakeQuestionOther
        }
        mainContact {
          firstName
          lastName
          emails
          newsletterEmails
          phone
          jobTitle
        }
        categories {
          primary
          primaryOther
          secondary
          secondaryOther
        }
        about {
          employeesNumber
          instagram
          companyCertified
          companyCertifiedAgency
          city
          stateCode {
            value
            label
          }
          state
          yearFounded {
            value
            label
          }
          brandBio
        }
        specs {
          ownership {
            blackOwned
            blackFronted
            blackFounded
            indigenousOwned
            majorityWomenOwned
          }
          production {
            companyProvide {
              value
              label
            }
            products {
              label
              value
            }
            ethicallySustainableMade {
              label
              value
            }
            hasFactor
            capacity
            services {
              label
              value
            }
            servicesOther
          }
          retailers {
            current
            interests {
              value
              label
            }
          }
          finances {
            avgProductUnitPrice
            lastAnnualRevenue {
              label
              value
            }
            projectedRevenue {
              label
              value
            }
            actualRevenue
            raisedCapital {
              label
              value
            }
            capitalOrganizationsKind {
              label
              value
            }
            acceleratorPrograms
            acceleratorProgramsDescription
            wantsInvestorsFunding
          }
        }
      }
    }
  }
`;

export const Q_BUSINESS_ABOUT = gql`
  query business($slug: String!) {
    business(slug: $slug) {
      id
      data {
        ownerId
        registerStatus
        award2025TypeformId
        award2025SephoraTypeformId
        isValidated
        isPublished
        name
        headerImage
        website
        about {
          instagram
          story
          products {
            image
            label
          }
          press {
            label
            url
          }
          additionalContent {
            header
            description
          }
          moodImages {
            position
            image
          }
        }
        specs {
          ownership {
            blackOwned
            indigenousOwned
            majorityWomenOwned
          }
        }
      }
      recommendedBusinesses {
        id
        data {
          name
          slug
          headerImage
        }
      }
    }
  }
`;

export const Q_BUSINESS_SPECS = gql`
  query business($slug: String!) {
    business(slug: $slug) {
      data {
        ownerId
        isValidated
        isPublished
        name
        specs {
          ownership {
            blackOwned
            blackFounded
            blackFronted
            indigenousOwned
            majorityWomenOwned
          }
          production {
            hasFactor
            capacity
            companyProvide {
              label
              value
            }
            services {
              value
              label
            }
            servicesOther
            products {
              value
              label
            }
            ethicallySustainableMade {
              value
              label
            }
          }
          retailers {
            current
            interests {
              label
              value
            }
          }
          finances {
            wantsInvestorsFunding
          }
        }
      }
    }
  }
`;

export const Q_BUSINESS_CONTACT = gql`
  query business($slug: String!) {
    business(slug: $slug) {
      data {
        ownerId
        isValidated
        isPublished
        contact {
          locations {
            label
            country
            state
            city
            description
            persons {
              firstName
              lastName
              title
              phone
              email
            }
          }
        }
      }
    }
  }
`;

export const M_BUSINESS_REGISTER_FOR_AWARD_EDIT = gql`
  mutation editBusiness($id: String!, $typeformId: String!) {
    editBusiness(businessInput: { id: $id, award2025TypeformId: $typeformId })
  }
`;
export const M_BUSINESS_REGISTER_FOR_AWARD_MODIFIED = gql`
  mutation updateTypeformLastModified(
    $id: String!
    $award2025LastModified: String!
  ) {
    editBusiness(
      businessInput: { id: $id, award2025LastModified: $award2025LastModified }
    )
  }
`;

export const M_BUSINESS_REGISTER_FOR_Sephora_AWARD_EDIT = gql`
  mutation editBusiness($id: String!, $typeformId: String!) {
    editBusiness(
      businessInput: { id: $id, award2025SephoraTypeformId: $typeformId }
    )
  }
`;
export const M_BUSINESS_REGISTER_FOR_Sephora_AWARD_MODIFIED = gql`
  mutation updateTypeformLastModified(
    $id: String!
    $award2025SephoraLastModified: String!
  ) {
    editBusiness(
      businessInput: {
        id: $id
        award2025SephoraLastModified: $award2025SephoraLastModified
      }
    )
  }
`;

export const M_BUSINESS_PUBLISH_EDIT = gql`
  mutation editBusiness($id: String!, $isPublished: Boolean!) {
    editBusiness(businessInput: { id: $id, isPublished: $isPublished })
  }
`;

export const M_BUSINESS_CONTACT_EDIT = gql`
  mutation editBusiness($id: String!, $locations: [LocationInput]) {
    editBusiness(businessInput: { id: $id, contact: { locations: $locations } })
  }
`;

export const M_BUSINESS_ABOUT_EDIT = gql`
  mutation editBusiness(
    $id: String!
    $website: String
    $instagram: String
    $story: String
    $products: ProductInput
    $press: PressInput
    $additionalContent: AdditionalContentInput
    $moodImages: MoodImageInput
  ) {
    editBusiness(
      businessInput: {
        id: $id
        website: $website
        about: {
          instagram: $instagram
          story: $story
          products: $products
          press: $press
          additionalContent: $additionalContent
          moodImages: $moodImages
        }
      }
    )
  }
`;

export const M_BUSINESS_EDIT = gql`
  mutation editBusiness(
    $id: String!
    $name: String!
    $registerStatus: String!
    $website: String!
    $about: AboutInput!
    $categories: CategoriesInput!
    $specs: SpecsInput!
    $mainContact: MainContactInput!
    $otherInfo: OtherInfoInput!
  ) {
    editBusiness(
      businessInput: {
        id: $id
        name: $name
        registerStatus: $registerStatus
        website: $website
        categories: $categories
        about: $about
        specs: $specs
        mainContact: $mainContact
        otherInfo: $otherInfo
      }
    )
  }
`;

export const M_BUSINESS_HEADER = gql`
  mutation editBusiness($id: String!, $headerImage: String!) {
    editBusiness(businessInput: { id: $id, headerImage: $headerImage })
  }
`;

export const M_BUSINESS_MOOD = gql`
  mutation editBusiness($id: String!, $moodImages: [MoodImageInput!]) {
    editBusiness(businessInput: { id: $id, about: { moodImages: $moodImages } })
  }
`;

// export const M_BUSINESS_ABOUT_ADDITIONAL = gql`
//   mutation editBusiness($id: String!, $additionalContent: AdditionalContentInput) {
//     editBusiness(
//       businessInput: { id: $id, about: { additionalContent: $additionalContent } }
//     )
//   }
// `;

// export const M_BUSINESS_ABOUT_PRESS = gql`
//   mutation editBusiness($id: String!, $press: PressInput) {
//     editBusiness(businessInput: { id: $id, about: { press: $press } })
//   }
// `;

export const M_BUSINESS_SPECS_EDIT = gql`
  mutation editBusiness(
    $id: String!
    $ownership: OwnershipInput
    $production: ProductInput
    $finances: FinancesInput
    $retailers: RetailersInput
  ) {
    editBusiness(
      businessInput: {
        id: $id
        specs: {
          ownership: $ownership
          production: $production
          finances: $finances
          retailers: $retailers
        }
      }
    )
  }
`;
