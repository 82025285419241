import React from 'react';
import styles from './favorite-icon.module.sass';

type Props = {
  selected?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const FavoriteIcon = ({ selected, onClick }: Props) => {
  return (
    <button className={styles.wrapper} onClick={onClick}>
      <svg
        className={`${styles.container} ${selected ? styles.selected : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        width="22.489"
        height="20.615"
        viewBox="0 0 22.489 20.615"
      >
        <path
          className={styles.fill}
          d="M 11.24467754364014 20.00918388366699 C 8.531447410583496 18.13918495178223 6.287955760955811 16.25632095336914 4.572577476501465 14.409255027771 C 2.992767572402954 12.70817470550537 1.854317545890808 11.03249454498291 1.188857555389404 9.428784370422363 C -0.06960245966911316 6.394954681396484 0.6187875270843506 4.33174467086792 1.417877554893494 3.135164737701416 C 2.503347635269165 1.509734869003296 4.467437744140625 0.5000048279762268 6.543647766113281 0.5000048279762268 C 8.282647132873535 0.5000048279762268 9.814657211303711 1.202974796295166 10.85746765136719 2.479414939880371 L 11.24467754364014 2.953374862670898 L 11.63188743591309 2.479414939880371 C 12.67469787597656 1.202974796295166 14.20670795440674 0.5000048279762268 15.94570732116699 0.5000048279762268 C 18.02213668823242 0.5000048279762268 19.98630714416504 1.509674787521362 21.07170677185059 3.134994745254517 C 21.87075805664062 4.331504821777344 22.55906677246094 6.394694805145264 21.30047798156738 9.428834915161133 C 20.63503837585449 11.03249454498291 19.4965877532959 12.70817470550537 17.91677665710449 14.409255027771 C 16.2013988494873 16.25632095336914 13.95790767669678 18.13918495178223 11.24467754364014 20.00918388366699 Z"
        />
        <path
          className={styles.outline}
          d="M 11.24467754364014 19.40060043334961 C 16.2011661529541 15.93913650512695 19.51225662231445 12.43373775482178 20.83863830566406 9.237264633178711 C 22.0125675201416 6.407214641571045 21.38722801208496 4.50776481628418 20.65590667724609 3.412674903869629 C 19.66208839416504 1.924484848976135 17.85723686218262 1.000004887580872 15.94570732116699 1.000004887580872 C 14.35964775085449 1.000004887580872 12.9651575088501 1.637744784355164 12.01909732818604 2.795754909515381 L 11.24467754364014 3.743664741516113 L 10.47025775909424 2.795744895935059 C 9.52420711517334 1.637744784355164 8.129717826843262 1.000004887580872 6.543647766113281 1.000004887580872 C 4.632327556610107 1.000004887580872 2.827577590942383 1.924554824829102 1.833677530288696 3.412844896316528 C 1.102317571640015 4.507994651794434 0.4768975377082825 6.407464981079102 1.650677561759949 9.237154960632324 C 2.977097749710083 12.43373394012451 6.28818941116333 15.93913650512695 11.24467754364014 19.40060043334961 M 11.24467754364014 20.615234375 C 5.198487758636475 16.49905395507812 2.027497529983521 12.75442504882812 0.7270375490188599 9.620414733886719 C -2.756842374801636 1.221634745597839 7.194167137145996 -2.794904947280884 11.24467754364014 2.163074731826782 C 15.29519081115723 -2.794907093048096 25.24620819091797 1.221636652946472 21.7623176574707 9.620414733886719 C 20.46185684204102 12.75442504882812 17.29086685180664 16.49905395507812 11.24467754364014 20.615234375 Z"
        />
      </svg>
    </button>
  );
};

export default FavoriteIcon;
