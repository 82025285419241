//@flow
import { Field } from 'formik';
import React, { useState, useRef } from 'react';
import Compressor from 'compressorjs';
import FixedRatioImage from '../base/FixedRatioImage';
import EditorCameraIcon from '../icons/EditorCameraIcon';

import styles from './gallery-image.module.sass';

interface Props {
  item: any;
  imageIndex: number;
  editMode: boolean;
  selected: boolean;
  length: number;
  position: string;
  zindex: number;
  onPressImage: () => void;
  updateImage: (string) => void;
}

const GalleryImage = ({
  editMode,
  imageIndex,
  item,
  onPressImage,
  updateImage,
}: Props) => {
  const imageInputRef = useRef<HTMLInputElement>();
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = () => {
    const reader = new FileReader();
    const image = imageInputRef.current.files[0];
    new Compressor(image, {
      quality: 0.85,
      minWidth: 480,
      minHeight: 480,
      maxWidth: 640,
      maxHeight: 640,
      width: 640,
      success: (result) => {
        reader.readAsDataURL(result);
      },
    });

    reader.onload = (e) => {
      const image = e.target.result;
      updateImage(image);
      setImagePreview(image);
    };
    // reader.readAsDataURL(imageInputRef.current.files[0]);
  };

  const onClick = () => {
    onPressImage();
  };

  const dataImage =
    item && item.image && item.image.length > 0 && item.image.includes('/uploads/')
      ? item.image
      : '';

  // if (!dataImage && !editMode) return null;

  return (
    <div
      className={`${styles.img} ${editMode ? styles.editmode : ''}`}
      onClick={!editMode ? onClick : null}
    >
      <FixedRatioImage image={imagePreview ? imagePreview : dataImage} ratio="1x1" />
      {editMode && (
        <div className={styles.form}>
          <label className={styles.formupload} htmlFor={`image-input-${imageIndex}`}>
            <EditorCameraIcon />
          </label>
          <input
            type="file"
            ref={imageInputRef}
            id={`image-input-${imageIndex}`}
            className="sr"
            onChange={handleImageChange}
          />
          <Field
            className="sr"
            id={`image-${imageIndex}`}
            name={`image-${imageIndex}`}
          />
        </div>
      )}
    </div>
  );
};

export default GalleryImage;
