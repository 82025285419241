import React, { useEffect, useRef, useState } from 'react';
import { Colors } from '../../helpers/constants';
import useScrollPosition from '../../helpers/useScrollPosition';
import styles from './wave-scroller.module.sass';

interface Props {
  color?: string;
  min?: number;
  max?: number;
  stationary?: number;
}

const WaveScroller = ({ color, min, max, stationary }: Props) => {
  const waveRef = useRef<SVGUseElement>();
  const waveColor = color ? color : Colors.WHITE_FLORAL;
  const scrollPosition = useScrollPosition();
  const [horizontalPosition, setHorizontalPosition] = useState(-80);

  useEffect(() => {
    if (!waveRef || !waveRef.current) return;
    if (stationary) {
      setHorizontalPosition(stationary);
      return;
    }
    const { y } = waveRef.current.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    const minValue = min ? min : -80;
    const maxValue = max ? max : 100;
    const range =
      minValue < 0
        ? Math.abs(minValue) + Math.abs(maxValue)
        : Math.abs(maxValue) - Math.abs(minValue);
    const correction = innerWidth < 768 ? 50 : innerWidth < 1024 ? 100 : 150;
    if (y + correction > 0 && y < innerHeight) {
      let percentage = 1 - (y + correction) / innerHeight;
      if (percentage < 0) percentage = 0;
      if (percentage > 1) percentage = 1;
      const targetValue = minValue + percentage * range;
      setHorizontalPosition(targetValue);
    }
  }, [scrollPosition]);

  return (
    <svg
      className={styles.waves}
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className={styles.parallax}>
        <use
          ref={waveRef}
          style={{ transform: `translate3d(${horizontalPosition}px, 0, 0)` }}
          xlinkHref="#gentle-wave"
          x="48"
          y="7"
          fill={waveColor}
        />
      </g>
    </svg>
  );
};

export default WaveScroller;
