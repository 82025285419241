//@flow
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
// import { useAuth } from '../../hooks';
import { BusinessEditAbout, BusinessEditResponse } from '../../types/business';
import { M_BUSINESS_ABOUT_EDIT } from '../../graphql/business';
import FormObjectArrayField from '../form/ui/FormObjectArrayField';
import EditorIcon from '../icons/EditorIcon';
import styles from './business-press.module.sass';

const PressValidationSchema = Yup.object().shape({
  press: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required('Please enter an article title'),
      url: Yup.string()
        .url('Please enter a valid url')
        .required('Please enter a valid url'),
    })
  ),
});

const pressObjects = [
  {
    key: 'label',
    label: 'Enter a description',
    fieldType: 'textarea',
    maxLength: 200,
  },
  {
    key: 'url',
    label: 'https://... link',
    fieldType: 'input',
  },
];

type Props = {
  previewMode: boolean;
  businessId: string;
  data: any;
  isEditable: boolean;
  onUpdate: () => void;
  copy: {
    about_link_header: string;
    about_editor_link_hint: string;
  };
};

const BusinessPress = ({
  previewMode,
  businessId,
  data,
  isEditable,
  onUpdate,
  copy,
}: Props) => {
  // const { user } = useAuth();
  const [editor, setEditor] = useState(false);

  const [editBusinessPress, { loading, error }] = useMutation<
    BusinessEditResponse,
    BusinessEditAbout
  >(M_BUSINESS_ABOUT_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        setEditor(false);
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  const handleSubmit = (values) => {
    editBusinessPress({
      variables: {
        id: businessId, //user.business.id,
        press: values.press,
      },
    });
  };

  return (
    <div className={styles.press}>
      {isEditable && !editor && !previewMode && (
        <>
          {(!data || data.length <= 0) && (
            <>
              <h3>{copy.about_link_header}</h3>
              <p className={styles.hint}>{copy.about_editor_link_hint}</p>
              <EditorIcon onClick={() => setEditor(true)} />
            </>
          )}
        </>
      )}
      {editor ? (
        <>
          <h3>{copy.about_link_header}</h3>
          <Formik
            validationSchema={PressValidationSchema}
            initialValues={{
              press: data,
            }}
            onSubmit={handleSubmit}
          >
            <Form className={styles.form}>
              <FormObjectArrayField
                name="press"
                label=""
                addLabel="Add Link"
                items={pressObjects}
                maxLength={3}
              />
              <div className={`${styles.formbuttons} ${styles.end}`}>
                <button
                  type="button"
                  className="button-inline small"
                  onClick={() => setEditor(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="button primary small">
                  Save
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  <p className="error">{error.message}</p>
                </div>
              )}
            </Form>
          </Formik>
        </>
      ) : (
        <>
          {data && data.length > 0 && (
            <>
              <h3>{copy.about_link_header}</h3>
              {isEditable && !previewMode && (
                <div className={styles.edit}>
                  <EditorIcon onClick={() => setEditor(true)} />
                </div>
              )}
              <div className={styles.items} data-length={data.length}>
                {data.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <h3>{'“'}</h3>
                    <p>{item.label}</p>
                    <a
                      className="btn-inline"
                      href={item.url}
                      target="_blank"
                      rel="norel noreferrer"
                    >
                      Read More
                    </a>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessPress;
