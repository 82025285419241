//@flow
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { M_BUSINESS_SPECS_EDIT } from '../../../graphql/business';
import { BusinessEditResponse } from '../../../types/business';
import { useCategories } from '../../../hooks';
import EditorIcon from '../../icons/EditorIcon';
import FormTextField from '../../form/ui/FormTextField';
import FormSelectField from '../../form/ui/FormSelectField';
import styles from './business-specs.module.sass';
import { cleanDataFromTypes } from '../../../helpers/dataCleaning';

type Props = {
  previewMode: boolean;
  isEditable: boolean;
  businessId: string;
  data: any;
  onUpdate: () => void;
  onLayoutUpdate: () => void;
};

const SpecsRetailers = ({
  previewMode,
  isEditable,
  businessId,
  data,
  onUpdate,
  onLayoutUpdate,
}: Props) => {
  const { categories } = useCategories();
  // const { user } = useAuth();
  const [detailEditor, setDetailEditor] = useState(false);

  useEffect(() => {
    if (data) setDetailEditor(false);
  }, [data]);

  const [
    editBusinessRetailers,
    { loading, error },
  ] = useMutation<BusinessEditResponse>(M_BUSINESS_SPECS_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  useEffect(() => {
    onLayoutUpdate();
  }, [detailEditor]);

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  const handleFinancesSubmit = (values) => {
    editBusinessRetailers(
      cleanDataFromTypes({
        variables: {
          id: businessId, //user.business.id,
          ownership: {
            blackOwned: data.ownership.blackOwned,
            blackFounded: data.ownership.blackFounded,
            blackFronted: data.ownership.blackFronted,
            indigenousOwned: data.ownership.indigenousOwned,
            majorityWomenOwned: data.ownership.majorityWomenOwned,
          },
          production: {
            companyProvide: data.production.companyProvide,
            services: data.production.services,
            servicesOther: data.production.servicesOther,
            products: data.production.products,
            ethicallySustainableMade: data.production.ethicallySustainableMade,
            hasFactor: data.production.hasFactor,
            capacity: data.production.capacity,
          },
          retailers: {
            current: values.retailers.current,
            interests: values.retailers.interests,
          },
          finances: {
            wantsInvestorsFunding: data.finances.wantsInvestorsFunding,
          },
        },
      })
    );
  };

  if (!categories) return null;

  const retailerInterests = data.retailers.interests.map((interest) => {
    return _.find(categories.data.retailers, (retailer) => {
      return retailer.value === interest.value;
    });
  });

  const setupInitialValues = (data) => {
    return cleanDataFromTypes({
      retailers: {
        current:
          data.retailers && data.retailers.current ? data.retailers.current : '',
        interests:
          data.retailers && data.retailers.interests ? retailerInterests : '',
      },
    });
  };

  return (
    <div
      className={`${styles.info} ${styles.retailers} ${detailEditor ? styles.editor : ''
        }`}
    >
      <h3>Retailers</h3>
      {isEditable && detailEditor === true && !previewMode ? (
        <Formik
          initialValues={setupInitialValues(data)}
          onSubmit={handleFinancesSubmit}
        >
          <Form className={styles.form}>
            <div className="full-width column">
              <FormTextField
                name="retailers.current"
                label="Current Major Retailers"
                isBox={true}
                showCounter={true}
                light
              />
              <FormSelectField
                isMulti={true}
                name="retailers.interests"
                label="Select Interested Retailers"
                options={categories.data.retailers}
                light
              />
            </div>
            <div className={`${styles.formbuttons} ${styles.end}`}>
              <button
                type="button"
                className="button-inline small"
                onClick={() => setDetailEditor(false)}
              >
                Cancel
              </button>
              <button type="submit" className="button secondary small">
                Save
              </button>
            </div>
            {error && (
              <div className={styles.error}>
                <p className="error">{error.message}</p>
              </div>
            )}
          </Form>
        </Formik>
      ) : (
        <>
          <article className="column center">
            <h5>Current Major Retailers</h5>
            <p>
              {data.retailers.current.map((item, index) => (
                <span key={index}>
                  {item}
                  {index < data.retailers.current.length - 1 && ', '}
                </span>
              ))}
            </p>
          </article>
          <article className="column center">
            <h5>Interested in working with:</h5>
            {data.retailers.interests.map((item, index) => {
              return <p key={index}>{item.label}</p>;
            })}
          </article>
          {isEditable && detailEditor === false && !previewMode && (
            <article className="column center">
              <EditorIcon onClick={handleDetailEditor} />
            </article>
          )}
        </>
      )}
    </div>
  );
};

export default SpecsRetailers;
