//@flow
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { ModalTypes } from '../../context/modalContext';
import {
  BusinessEditAbout,
  BusinessEditResponse,
  BusinessRecord,
} from '../../types/business';
import { useAuth, useModal, useWindowSize } from '../../hooks';
import { M_BUSINESS_MOOD } from '../../graphql/business';
import useScrollPosition from '../../helpers/useScrollPosition';
import { FavoriteIcon } from '../icons';
import WaveScroller from '../base/WaveScroller';
import HeaderImageForm from './HeaderImageForm';
import styles from './business-header.module.sass';

type Props = {
  previewMode: boolean;
  data: {
    business: BusinessRecord;
  };
  onUpdate: () => void;
};

const BusinessHeader = ({ previewMode, data, onUpdate }: Props) => {
  const { user } = useAuth();
  const { pathname } = useRouter();
  const scrollPosition = useScrollPosition();
  const { windowWidth } = useWindowSize();
  const titleRef = useRef<HTMLDivElement>();
  const { setFavoriteBusiness, setModalType } = useModal();
  const [maxImageCount, setMaxImageCount] = useState(3);
  const [imagesHidden, setImagesHidden] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (!titleRef || !titleRef.current) {
      setImagesHidden(false);
      setCollapsed(false);
      return;
    }
    const { top } = titleRef.current.getBoundingClientRect();
    setImagesHidden(top <= 30);
    setCollapsed(scrollPosition > 100);
  }, [scrollPosition, pathname]);

  useEffect(() => {
    let maxCount = 3;
    if (windowWidth < 768) {
      maxCount = 1;
    } else if (windowWidth < 1024) {
      maxCount = 2;
    }
    if (maxCount !== maxImageCount) {
      setMaxImageCount(maxCount);
    }
  }, [windowWidth]);

  const [editBusiness, { loading, error }] = useMutation<
    BusinessEditResponse,
    BusinessEditAbout
  >(M_BUSINESS_MOOD, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  if (!data) {
    return (
      <section className={`section-first inner-width ${styles.header}`}>
        <article className="full-width row between" />
      </section>
    );
  }

  const { business } = data;
  const isOwner = user && user.id === data.business.data.ownerId;
  const isAdmin =
    user && user.data.adminRole && user.data.adminRole.toUpperCase() === 'ADMIN';
  const isEditable = isOwner || isAdmin;

  const isFavorite =
    data &&
    user &&
    user.data.favorites &&
    _.some(user.data.favorites, (board) => {
      return _.some(board.businesses, (businessId) => {
        return businessId === business.id;
      });
    });

  const handleFavoriteClicked = () => {
    if (isFavorite) return;
    setFavoriteBusiness(business);
    setModalType(ModalTypes.FAVORITE);
  };

  const handleImageSubmit = (index: number, image: any) => {
    editBusiness({
      variables: {
        id: data.business.id, //user.business.id,
        moodImages: [
          {
            position: 1,
            image: index === 1 && image ? image : null,
            keepOldValue: index === 1 ? false : true,
          },
          {
            position: 2,
            image: index === 2 && image ? image : null,
            keepOldValue: index === 2 ? false : true,
          },
          {
            position: 3,
            image: index === 3 && image ? image : null,
            keepOldValue: index === 3 ? false : true,
          },
        ],
      },
    });
  };

  const renderImages = () => {
    const headerImages = [];
    let image = null;
    if (hasImageTwo) {
      image = (
        <div key="image-2" style={{ backgroundImage: `url(${imageTwo.image})` }} />
      );
      headerImages.push(image);
    }
    if (hasImageOne && headerImages.length < maxImageCount) {
      image = (
        <div key="image-1" style={{ backgroundImage: `url(${imageOne.image})` }} />
      );
      headerImages.unshift(image);
    }
    if (hasImageThree && headerImages.length < maxImageCount) {
      image = (
        <div key="image-3" style={{ backgroundImage: `url(${imageThree.image})` }} />
      );
      headerImages.push(image);
    }

    return headerImages;
  };

  /* ======================== */
  /* DUMMY IMAGES FOR TESTING */
  /* ======================== */
  // const moodImages = [
  //   {
  //     position: 1,
  //     image: '/dummy/business1.png',
  //   },
  //   {
  //     position: 2,
  //     image: '/dummy/business2.png',
  //   },
  //   {
  //     position: 3,
  //     image: '/dummy/business3.png',
  //   },
  // ];

  // const imageOne = moodImages ? _.find(moodImages, { position: 1 }) : '';
  // const imageTwo = moodImages ? _.find(moodImages, { position: 2 }) : '';
  // const imageThree = moodImages ? _.find(moodImages, { position: 3 }) : '';

  // const hasImageOne = true;
  // const hasImageTwo = true;
  // const hasImageThree = true;
  /* ========= END ========== */

  const imageOne =
    business.data.about && business.data.about.moodImages
      ? _.find(business.data.about.moodImages, { position: 1 })
      : null;
  const imageTwo =
    business.data.about && business.data.about.moodImages
      ? _.find(business.data.about.moodImages, { position: 2 })
      : null;
  const imageThree =
    business.data.about && business.data.about.moodImages
      ? _.find(business.data.about.moodImages, { position: 3 })
      : null;

  const hasImageOne =
    imageOne &&
    imageOne.image &&
    imageOne.image.length > 0 &&
    imageOne.image.includes('/uploads/');

  const hasImageTwo =
    imageTwo &&
    imageTwo.image &&
    imageTwo.image.length > 0 &&
    imageTwo.image.includes('/uploads/');

  const hasImageThree =
    imageThree &&
    imageThree.image &&
    imageThree.image.length > 0 &&
    imageThree.image.includes('/uploads/');

  return (
    <>
      <section
        className={`inner-width ${styles.imagecontainer} ${
          isEditable ? styles.editable : ''
        } ${collapsed ? styles.collapsed : ''} ${imagesHidden ? styles.hidden : ''}`}
        style={{ transform: `translate3d(0, -${scrollPosition / 4}px, 0)` }}
      >
        {isEditable && !previewMode ? (
          <>
            <div className={`${styles.images}`}>
              <HeaderImageForm
                index={1}
                initialImage={imageOne}
                onSubmit={(image) => handleImageSubmit(1, image)}
              />
              <HeaderImageForm
                index={2}
                initialImage={imageTwo}
                onSubmit={(image) => handleImageSubmit(2, image)}
              />
              <HeaderImageForm
                index={3}
                initialImage={imageThree}
                onSubmit={(image) => handleImageSubmit(3, image)}
              />
            </div>
            {error && (
              <div className={styles.error}>
                <p className="error">{error.message}</p>
              </div>
            )}
          </>
        ) : (
          <div className={`${styles.images}`}>{renderImages()}</div>
        )}
      </section>
      <section
        ref={titleRef}
        className={`full-width ${styles.header} ${
          isEditable ? styles.editable : ''
        }`}
      >
        <WaveScroller stationary={-13} />
        <article
          className={`inner-width column center bg-transparent ${styles.title}`}
        >
          <h1 className="sr">{data.business.data.name}</h1>
          <FavoriteIcon selected={isFavorite} onClick={handleFavoriteClicked} />
          <h2>{data.business.data.name}</h2>
        </article>
      </section>
    </>
  );
};

export default BusinessHeader;
