//@flow
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import { M_BUSINESS_SPECS_EDIT } from '../../../graphql/business';
import { BusinessEditResponse } from '../../../types/business';
import EditorIcon from '../../icons/EditorIcon';
// import FormMinMaxField from '../../form/ui/FormMinMaxField';
import FormBooleanField from '../../form/ui/FormBooleanField';
import styles from './business-specs.module.sass';
import { cleanDataFromTypes } from '../../../helpers/dataCleaning';

type Props = {
  previewMode: boolean;
  isEditable: boolean;
  businessId: string;
  data: any;
  onUpdate: () => void;
  onLayoutUpdate: () => void;
};

const SpecsFinances = ({
  previewMode,
  isEditable,
  businessId,
  data,
  onUpdate,
  onLayoutUpdate,
}: Props) => {
  const [detailEditor, setDetailEditor] = useState(false);

  const [
    editBusinessFinances,
    { loading, error },
  ] = useMutation<BusinessEditResponse>(M_BUSINESS_SPECS_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  useEffect(() => {
    onLayoutUpdate();
  }, [detailEditor]);

  useEffect(() => {
    if (data) setDetailEditor(false);
  }, [data]);

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  const handleFinancesSubmit = (values) => {
    editBusinessFinances(
      cleanDataFromTypes({
        variables: {
          id: businessId, //user.business.id,
          ownership: {
            blackOwned: data.ownership.blackOwned,
            blackFounded: data.ownership.blackFounded,
            blackFronted: data.ownership.blackFronted,
            indigenousOwned: data.ownership.indigenousOwned,
            majorityWomenOwned: data.ownership.majorityWomenOwned,
          },
          production: {
            companyProvide: data.production.companyProvide,
            services: data.production.services,
            servicesOther: data.production.servicesOther,
            products: data.production.products,
            ethicallySustainableMade: data.production.ethicallySustainableMade,
            hasFactor: data.production.hasFactor,
            capacity: data.production.capacity,
          },
          retailers: {
            current: data.retailers.current,
            interests: data.retailers.interests,
          },
          finances: {
            wantsInvestorsFunding: values.finances.wantsInvestorsFunding,
          },
        },
      })
    );
  };

  const setupInitialValues = (data) => {
    return cleanDataFromTypes({
      finances: {
        // hasCredit:
        //   data.finances && data.finances.hasCredit
        //     ? data.finances.hasCredit
        //     : false,
        // hasInvestors:
        //   data.finances && data.finances.hasInvestors
        //     ? data.finances.hasInvestors
        //     : false,
        wantsInvestorsFunding:
          data.finances && data.finances.wantsInvestorsFunding
            ? data.finances.wantsInvestorsFunding
            : false,
        // annualRevenue: {
        //   min:
        //     data.finances && data.finances.annualRevenue.min
        //       ? data.finances.annualRevenue.min
        //       : 100000,
        //   max:
        //     data.finances && data.finances.annualRevenue.max
        //       ? data.finances.annualRevenue.max
        //       : 1000000,
        // },
        // averagePrice: {
        //   min:
        //     data.finances && data.finances.averagePrice.min
        //       ? data.finances.averagePrice.min
        //       : 10,
        //   max:
        //     data.finances && data.finances.averagePrice.max
        //       ? data.finances.averagePrice.max
        //       : 100,
        // },
      },
    });
  };

  return (
    <div className={styles.info}>
      <h3>Finances</h3>
      {isEditable && detailEditor === true && !previewMode ? (
        <Formik
          initialValues={setupInitialValues(data)}
          onSubmit={handleFinancesSubmit}
        >
          {({ values }) => (
            <Form className={styles.form}>
              <div className="full-width column">
                {/*<div className={`column start ${styles.input}`}>*/}
                {/*  <FormMinMaxField*/}
                {/*    name="finances.averagePrice"*/}
                {/*    label={'Estimated / Average Price Range ($USD) Goods & Services'}*/}
                {/*  />*/}
                {/*</div>*/}

                {/*<div className={`column start ${styles.input}`}>*/}
                {/*  <FormMinMaxField*/}
                {/*    name="finances.annualRevenue"*/}
                {/*    label={'Estimated Annual Revenue ($USD)'}*/}
                {/*  />*/}
                {/*</div>*/}

                {/*<FormBooleanField*/}
                {/*  name="finances.hasCredit"*/}
                {/*  label="Access to a line of credit"*/}
                {/*  selected={values.finances.hasCredit}*/}
                {/*/>*/}
                {/*<FormBooleanField*/}
                {/*  name="finances.hasInvestors"*/}
                {/*  label="Investor(s)"*/}
                {/*  selected={values.finances.hasInvestors}*/}
                {/*/>*/}
                <FormBooleanField
                  name="finances.wantsInvestorsFunding"
                  label="Looking for investors or funding"
                  selected={values.finances.wantsInvestorsFunding}
                />
              </div>
              <div className={`${styles.formbuttons} ${styles.end}`}>
                <button
                  type="button"
                  className="button-inline small"
                  onClick={() => setDetailEditor(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="button secondary small">
                  Save
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  <p className="error">{error.message}</p>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {/*<article className="column center">*/}
          {/*  <h5>Estimated / Average Price Range in USD Goods & Services</h5>*/}
          {/*  <p>*/}
          {/*    ${data.finances.averagePrice.min.toLocaleString()} – $*/}
          {/*    {data.finances.averagePrice.max.toLocaleString()}*/}
          {/*  </p>*/}
          {/*</article>*/}
          {/*<article className="column center">*/}
          {/*  <h5>Estimated Annual Revenue in USD</h5>*/}
          {/*  <p>*/}
          {/*    ${data.finances.annualRevenue.min.toLocaleString()} – $*/}
          {/*    {data.finances.annualRevenue.max.toLocaleString()}*/}
          {/*  </p>*/}
          {/*</article>*/}
          {data.finances.wantsInvestorsFunding && (
            <article className="column center">
              {data.finances.wantsInvestorsFunding && (
                <p>Looking for investors or funding</p>
              )}
              {/*{data.finances.hasCredit && <p>Access to a line of credit</p>}*/}
              {/*{data.finances.hasInvestors && <p>Has Investor(s)</p>}*/}
            </article>
          )}
          {isEditable && detailEditor === false && !previewMode && (
            <article className="column center">
              <EditorIcon onClick={handleDetailEditor} />
            </article>
          )}
        </>
      )}
    </div>
  );
};

export default SpecsFinances;
