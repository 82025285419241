//@flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import { BusinessEditAbout, BusinessEditResponse } from '../../types/business';
import { M_BUSINESS_ABOUT_EDIT } from '../../graphql/business';
// import { useAuth } from '../../hooks';
import FormTextField from '../form/ui/FormTextField';
import EditorIcon from '../icons/EditorIcon';
import styles from './business-additional.module.sass';
import InstagramHandle from './InstagramHandle';

type Props = {
  previewMode: boolean;
  businessId: string;
  data: any;
  instagram?: string;
  showInstagram: boolean;
  isEditable: boolean;
  onUpdate: () => void;
  copy: {
    form_instagram_label: string;
    about_editor_additional_hint: string;
    about_additional_default_header: string;
    about_link_header: string;
  };
};

const BusinessAdditional = ({
  previewMode,
  businessId,
  data,
  instagram,
  showInstagram,
  isEditable,
  onUpdate,
  copy,
}: Props) => {
  // const { user } = useAuth();
  const [editor, setEditor] = useState(false);

  const [editBusinessAdditional, { loading, error }] = useMutation<
    BusinessEditResponse,
    BusinessEditAbout
  >(M_BUSINESS_ABOUT_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        setEditor(false);
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  const handleSubmit = (values) => {
    editBusinessAdditional({
      variables: {
        id: businessId, //user.business.id,
        instagram: values.instagram,
        additionalContent: {
          header: values.header,
          description: values.description,
        },
      },
    });
  };

  return (
    <div className={`full-width ${styles.container}`}>
      {editor ? (
        <Formik
          initialValues={{
            header: data ? data.header : '',
            description: data ? data.description : '',
            instagram: instagram ? instagram : '',
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormTextField
              name="header"
              label="Header"
              size="large"
              maxLength={40}
              showCounter={true}
            />
            <FormTextField
              name="description"
              label="Description"
              isBox={true}
              maxLength={500}
              showCounter={true}
            />
            {showInstagram && (
              <FormTextField name="instagram" label={copy.form_instagram_label} />
            )}
            <div className={`${styles.formbuttons} ${styles.end}`}>
              <button
                type="button"
                className="button-inline small"
                onClick={() => setEditor(false)}
              >
                Cancel
              </button>
              <button type="submit" className="button primary small">
                Save
              </button>
            </div>
            {error && (
              <div className={styles.error}>
                <p className="error">{error.message}</p>
              </div>
            )}
          </Form>
        </Formik>
      ) : (
        <>
          {data && data.header ? (
            <h3>{data.header}</h3>
          ) : (
            <h3>{copy.about_additional_default_header}</h3>
          )}
          {data && data.description && <p>{data.description}</p>}
          {showInstagram && instagram && instagram.length > 0 && (
            <InstagramHandle data={instagram} />
          )}
        </>
      )}
      {isEditable && !editor && !previewMode && (
        <>
          {!data || (!data.header.length && !data.description.length) ? (
            <h4 className={styles.hint}>{copy.about_editor_additional_hint}</h4>
          ) : null}
          <div className={styles.edit}>
            <EditorIcon onClick={() => setEditor(true)} />
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessAdditional;
