import React from 'react';
import CheckIcon from './CheckIcon';
import styles from './buttons.module.sass';

type Props = {
  label?: string;
  light?: boolean;
  onClick?: () => void;
};

const ButtonConfirm = ({ label, light, onClick }: Props) => (
  <button
    type="submit"
    className={`${styles.button} ${light ? styles.light : ''}`}
    onClick={onClick}
  >
    <CheckIcon />
    <span className={styles.label}>{label ? label : 'Save'}</span>
  </button>
);

export default ButtonConfirm;
