import React from 'react';
import styles from './fixed-ratio-image.module.sass';

type Props = {
  image: string;
  ratio?: string;
  children?: any;
  label?: string;
  interactive?: boolean;
  customClass?: string;
  transparent?: boolean;
  disabled?: boolean;
};

const FixedRatioImage = ({
  image,
  ratio,
  children,
  label,
  interactive,
  customClass,
  transparent,
  disabled,
}: Props) => {
  return (
    <article
      className={`${styles.container}  ${interactive ? styles.interactive : ''} ${
        disabled ? styles.disabled : ''
      } ${customClass ? customClass : ''}`}
      data-ratio={ratio ? ratio : '16x9'}
    >
      <div className={styles.media}>
        <div className={styles.ratio}>
          <div
            className={`${styles.inner} ${transparent ? styles.transparent : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
        {children}
      </div>
      {label && (
        <div className={styles.label}>
          <p>{label}</p>
        </div>
      )}
    </article>
  );
};

export default FixedRatioImage;
