export default {
  formId: 'businessForm',
  formField: {
    /**   ##### Business information section Start ####### **/
    name: {
      name: 'name',
      label: "What's your company name?*",
      requiredErrorMsg: 'Please fill out the company name',
    },
    firstName: {
      name: 'firstName',
      label: "What's your first name?*",
      requiredErrorMsg: 'Please fill out your first name',
    },
    lastName: {
      name: 'lastName',
      label: "What's your last name?*",
      requiredErrorMsg: 'Please fill out your last name',
    },
    jobTitle: {
      name: 'jobTitle',
      label: 'Your Position*',
      requiredErrorMsg: 'This field is required',
    },
    emails: {
      name: 'emails',
      label:
        'What is the best email(s) to share opportunities with retailers or other partnerships?*',
      requiredErrorMsg: 'Please enter at least one email address',
      invalidErrorMsg: 'Please enter a valid email address',
    },
    newsletterEmails: {
      name: 'newsletterEmails',
      label: 'What is the best email(s) to share our monthly newsletter?',
      invalidErrorMsg: 'Please enter a valid email address',
    },
    phone: {
      name: 'phone',
      label: "What's the best phone number?*",
      requiredErrorMsg: 'Please enter a phone number',
      invalidErrorMsg: 'Please enter a valid phone number',
    },
    website: {
      name: 'website',
      label: 'Please link to your website*',
      requiredErrorMsg: 'Please enter your website',
      invalidErrorMsg:
        'Please enter a valid url, starting with http:// | https://',
    },
    instagram: {
      name: 'instagram',
      label: 'Company Instagram',
    },
    blackFounded: {
      name: 'blackFounded',
      label: 'Is your business Black Founded?',
    },
    blackOwned: {
      name: 'blackOwned',
      label: 'Is your company Black-owned?',
    },
    blackFronted: {
      name: 'blackFronted',
      label: 'Is your company Black-led?',
    },
    indigenousOwned: {
      name: 'indigenousOwned',
      label: 'Is your business Indigenous Owned?',
    },
    majorityWomenOwned: {
      name: 'majorityWomenOwned',
      label: 'Is your company at least 51% Women-owned?',
    },
    companyCertified: {
      name: 'companyCertified',
      label:
        'Is your company certified by NMSDC or other MWBE certification agency?',
    },
    companyCertifiedAgency: {
      name: 'companyCertifiedAgency',
      label: 'If yes, which agency?',
      requiredErrorMsg: 'This field is required',
    },
    city: {
      name: 'city',
      label: 'What city is your company based in?*',
      requiredErrorMsg: 'This field is required',
    },
    stateCode: {
      name: 'stateCode',
      label: 'What state is your company based in?*',
      requiredErrorMsg:
        'This field is required. If not in US, please select Other (not in US)',
    },
    state: {
      name: 'state',
      label: 'Please enter your region and country: *',
      requiredErrorMsg: 'This field is required',
    },
    yearFounded: {
      name: 'yearFounded',
      label: 'When did you start your business?*',
      requiredErrorMsg: 'This field is required',
    },
    /**  ##### Business information section End ####### **/

    /** ##### Brand Category Start ##### **/
    brandBio: {
      name: 'brandBio',
      label:
        'Please include a brief brand bio, 3-5 sentences summarizing your business: *',
      requiredErrorMsg: 'This field is required',
    },
    primary: {
      name: 'primary',
      label: 'What industry does your brand fall into? *',
    },
    primaryOther: {
      name: 'primaryOther',
      label: 'Please explain: *',
      requiredErrorMsg: 'This field is required',
    },
    secondary: {
      name: 'secondary',
      label: 'What secondary industry does your brand fall into? *',
    },
    secondaryOther: {
      name: 'secondaryOther',
      label: 'Please explain: *',
      requiredErrorMsg: 'This field is required',
    },
    avgProductUnitPrice: {
      name: 'avgProductUnitPrice',
      label: 'What is the average unit price for your brand?*',
      invalidErrorMsg: 'Please enter a positive number',
      requiredErrorMsg: 'This field is required',
    },
    companyProvide: {
      name: 'companyProvide',
      label: 'Does your company provide:',
      requiredErrorMsg: 'Please choose one of the options',
    },
    // If your company sells products
    products: {
      name: 'products',
      label: 'What type of product? Check all that apply.',
      requiredErrorMsg: 'This field is required',
    },
    ethicallySustainableMade: {
      name: 'ethicallySustainableMade',
      label: 'Are your products ethically / sustainably made?',
      requiredErrorMsg: 'This field is required',
    },
    hasFactor: {
      name: 'hasFactor',
      label: 'Do you outsource or use contract manufacturing?',
    },
    current: {
      name: 'current',
      label:
        'Please list the major retailers, if any, that currently sell your products?',
      requiredErrorMsg: 'This field is required',
    },
    capacity: {
      name: 'capacity',
      label:
        'Please describe your capacity/ability to scale. (e.g., What size orders can you take today? How ready are you to expand production scale in the near future?)',
      requiredErrorMsg: 'This field is required',
    },
    // If your company provides a service
    services: {
      name: 'services',
      label: 'What kind of service? Check all that apply.',
    },
    servicesOther: {
      name: 'servicesOther',
      label: 'Please explain what other service(s) you provide: *',
      requiredErrorMsg: 'This field is required',
    },
    /**  ##### Brand Category End ##### **/

    /**  ##### Business growth Start ##### **/
    employeesNumber: {
      name: 'employeesNumber',
      label:
        'How many employees does your company have including your founder?*',
      invalidErrorMsg: 'Please enter a positive number',
      requiredErrorMsg: 'This field is required',
    },
    lastAnnualRevenue: {
      name: 'lastAnnualRevenue',
      label:
        'What were your company’s annual revenues for the last full calendar year?*',
      requiredErrorMsg: 'This field is required',
    },
    projectedRevenue: {
      name: 'projectedRevenue',
      label:
        'What is your company’s projected revenue for the current calendar year?*',
      requiredErrorMsg: 'This field is required',
    },
    actualRevenue: {
      name: 'actualRevenue',
      label:
        'If you would like to apply for our current grants please include your ACTUAL revenue for 2023 or 2024, whichever is higher:',
    },
    raisedCapital: {
      name: 'raisedCapital',
      label: 'Have you raised capital?*',
      requiredErrorMsg: 'This field is required',
    },
    capitalOrganizationsKind: {
      name: 'capitalOrganizationsKind',
      label: 'Who or what kind of organizations invested in your brand?',
      requiredErrorMsg: 'This field is required',
    },
    wantsInvestorsFunding: {
      name: 'wantsInvestorsFunding',
      label:
        'Are you interested in exploring relationships with new investors?',
    },
    acceleratorPrograms: {
      name: 'acceleratorPrograms',
      label: 'Has your brand participated in any accelerator programs?',
      requiredErrorMsg: 'This field is required',
    },
    acceleratorProgramsDescription: {
      name: 'acceleratorProgramsDescription',
      label: 'Which program(s)? (Please fill in)',
      requiredErrorMsg: 'This field is required',
    },
    interests: {
      name: 'interests',
      label:
        'Are you interested in working with any of the following retailers (check all that apply) ',
    },
    intakeQuestion: {
      name: 'intakeQuestion',
      label: 'Where did you hear about us?*',
      requiredErrorMsg: 'This field is required.',
    },
    intakeQuestionOther: {
      name: 'intakeQuestionOther',
      label: 'Please explain where you heard about us: *',
      requiredErrorMsg: 'This field is required',
    },

    /**  ##### Business growth End ##### **/

    // headerImage: {
    //   name: 'headerImage',
    //   label: 'Upload an image for your business page*',
    //   requiredErrorMsg: 'Please upload an image',
    // },
    // coreProducts: {
    //   name: 'coreProducts',
    //   label: 'Please list your core product(s)*',
    //   requiredErrorMsg: 'This field is required',
    // },
    // blackPartners: {
    //   name: 'blackPartners',
    //   label: 'Is your business made up of Black Partners?',
    // },
    // maintainBlackOwnership: {
    //   name: 'maintainBlackOwnership',
    //   label:
    //     'How important is it to you that your company maintains Black ownership?',
    // },
    // indigenousPartners: {
    //   name: 'indigenousPartners',
    //   label: 'Is your business made up of Indigenous Partners?',
    // },
    // indigenousFounded: {
    //   name: 'indigenousFounded',
    //   label: 'Is your business Indigenous Founded?',
    // },
    // indigenousFronted: {
    //   name: 'indigenousFronted',
    //   label: 'Is your business Indigenous Fronted?',
    // },
    // maintainIndigenousOwnership: {
    //   name: 'maintainIndigenousOwnership',
    //   label:
    //     'How important is it to you that your company maintains Indigenous ownership?',
    // },
    // interests: {
    //   name: 'interests',
    //   label:
    //     'Are you interested in working with any of the following retailers (check all that apply)',
    // },
    // averagePrice: {
    //   name: 'averagePrice',
    //   label:
    //     'What is the estimated/average price range ($USD) of your goods/services?*',
    // },
    // annualRevenue: {
    //   name: 'annualRevenue',
    //   label: 'Please list your brand’s estimated annual revenue ($USD) range.*',
    // },
    // hasCredit: {
    //   name: 'hasCredit',
    //   label: 'Do you have access to a line of credit?',
    // },
    // hasInvestors: {
    //   name: 'hasInvestors',
    //   label: 'Do you have investor(s)?',
    // },
  },
};
