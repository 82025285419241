//@flow
import React from 'react';

type Props = {
  data: string;
};

const InstagramHandle = ({ data }: Props) => {
  const cleanIg = data ? data.toLowerCase().split('?')[0] : null;
  const igRegex = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?([\w.]+)\/?$/g;
  const igSections = igRegex.exec(cleanIg);
  const validIg = data && igSections && igSections.length > 1;
  const igUsername = validIg ? igSections[1] : '';

  if (!igUsername.length) return null;
  const instagramUrl = `https://instagram.com/${igUsername}`;

  return (
    <a
      href={instagramUrl}
      className="btn-inline"
      target="_blank"
      rel="norel noreferrer"
    >
      {`@${igUsername}`}
    </a>
  );
};

export default InstagramHandle;
