// @flow
import React from 'react';
import { ErrorMessage, Field, FieldArray, useField } from 'formik';
import ErrorMessageComponent from '../ErrorMessageComponent';
import SettingsButton, { SettingsType } from '../../buttons/SettingsButton';
import styles from './form-ui.module.sass';

type ObjectValue = {
  label: string;
  key: string;
  fieldType?: string;
  maxLength?: number;
};

type Props = {
  name: string;
  label: string;
  items: ObjectValue[];
  addLabel: string;
  maxLength?: number;
  light?: boolean;
};

const FormObjectArrayField = ({
  name,
  label,
  items,
  addLabel,
  maxLength,
  light,
}: Props) => {
  const [field] = useField(name);
  const maxCount = maxLength ? maxLength : 10;

  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {field.value && field.value.length > 0 ? (
              field.value.map((_item, index) => (
                <div className="full-width" key={`${index}`}>
                  <div
                    key={`${name}-${index}`}
                    className={`${styles.arrayitem} ${light ? styles.light : ''}`}
                  >
                    <>
                      <div className={`full-width column`}>
                        <div className={styles.array}>
                          {items.map((item) => (
                            <div className="column" key={`${index}-${item.key}`}>
                              <Field
                                as={item.fieldType}
                                type="text"
                                name={`${name}.${index}[${item.key}]`}
                                placeholder={item.label}
                                className={`${light ? styles.light : ''}`}
                                maxLength={item.maxLength}
                              />
                              {item.maxLength && (
                                <span className={styles.counter}>
                                  {field.value.length}/{item.maxLength}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                        {items.map((item) => (
                          <ErrorMessage
                            key={`error-${name}.${index}[${item.key}]`}
                            name={`${name}.${index}[${item.key}]`}
                            render={(msg) => <ErrorMessageComponent error={msg} />}
                          />
                        ))}
                      </div>
                      <SettingsButton
                        type={SettingsType.DELETE}
                        onClick={() => arrayHelpers.remove(index)}
                        light={light}
                      />
                    </>
                  </div>
                  {index === field.value.length - 1 &&
                    field.value.length < maxCount && (
                      <button
                        key={`${index}-add`}
                        type="button"
                        className={styles.add}
                        onClick={() => {
                          arrayHelpers.insert(field.value.length, {});
                        }}
                      >
                        + {addLabel}
                      </button>
                    )}
                </div>
              ))
            ) : (
              <div className={styles.arrayitem}>
                <div className={`full-width column`}>
                  <div className={styles.array}>
                    {items.map((item, index) => (
                      <Field
                        key={`${name}-${index}-${item.key}`}
                        type="text"
                        name={`${name}.${0}[${item.key}]`}
                        placeholder={item.label}
                        className={`${light ? styles.light : ''}`}
                      />
                    ))}
                  </div>
                  {items.map((item, index) => (
                    <ErrorMessage
                      key={`error-${name}.${index}[${item.key}]`}
                      name={`${name}.${0}[${item.key}]`}
                      render={(msg) => <ErrorMessageComponent error={msg} />}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default FormObjectArrayField;
