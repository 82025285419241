import React from 'react';
import styles from './lock-icon.module.sass';

type Props = {
  transparent?: boolean;
};

const LockIcon = ({ transparent }: Props) => {
  return (
    <div className={`${styles.wrapper} ${transparent ? styles.transparent : ''}`}>
      <svg
        className={styles.container}
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="16"
        viewBox="0 0 12 16"
      >
        <path
          className={styles.icon}
          d="M22.178,5.807V3.871a3.871,3.871,0,0,0-7.742,0V5.807h-.484A1.452,1.452,0,0,0,12.5,7.258v6.774a1.452,1.452,0,0,0,1.452,1.452h8.71a1.451,1.451,0,0,0,1.452-1.452V7.258a1.451,1.451,0,0,0-1.452-1.452Zm-6.774,0H21.21V3.871a2.9,2.9,0,0,0-5.807,0Z"
          transform="translate(-12.5)"
          fill="#893b2b"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default LockIcon;
