//@flow
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form, Formik, FieldArray } from 'formik';
import { useAuth, usePreview } from '../../../hooks';
import {
  M_BUSINESS_CONTACT_EDIT,
  Q_BUSINESS_CONTACT,
} from '../../../graphql/business';
import { PrismicBusinessDetail } from '../../../types/prismic';
import {
  BusinessEditResponse,
  Location,
  ContactInput,
} from '../../../types/business';
import FormTextField from '../../form/ui/FormTextField';
import FormObjectArrayField from '../../form/ui/FormObjectArrayField';
import { EditorIcon } from '../../icons';
import SettingsButton, { SettingsType } from '../../buttons/SettingsButton';
import styles from './business-contact.module.sass';

// const DUMMY_CONTACT = {
//   label: 'New York Headquarters',
//   city: 'New York',
//   state: 'NY',
//   country: 'United States',
//   description:
//     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui.',
//   persons: [
//     {
//       firstName: 'Firstname',
//       lastName: 'Lastname',
//       title: 'Brand Manager',
//       email: 'firstname.lastname@company.com',
//       phone: '123-456-7890',
//     },
//     {
//       firstName: 'Firstname',
//       lastName: 'Lastname',
//       title: 'Retail Manager',
//       email: 'firstname.lastname@company.com',
//       phone: '123-456-7890',
//     },
//   ],
// };

const contactObjects = [
  {
    key: 'firstName',
    label: 'First Name',
  },
  {
    key: 'lastName',
    label: 'Last Name',
  },
  {
    key: 'title',
    label: 'Job Title',
  },
  {
    key: 'email',
    label: 'Email Address',
  },
  {
    key: 'phone',
    label: 'Phone Number',
  },
];

const ValidationSchema = Yup.object().shape({
  locations: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required('Please enter an article title'),
      city: Yup.string().required('Please enter an article title'),
      country: Yup.string().required('Please enter an article title'),
      persons: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required('Please enter your firstname'),
          lastName: Yup.string().required('Please enter your lastname'),
          title: Yup.string().required('Please enter a job title'),
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Please enter a valid email'),
          phone: Yup.string().required('Please enter a phone number'),
        })
      ),
    })
  ),
});

type Props = {
  businessId: string;
  doc: PrismicBusinessDetail;
  onLayoutUpdate: () => void;
};

const BusinessContact = ({ businessId, doc, onLayoutUpdate }: Props) => {
  const { user } = useAuth();
  const { previewMode } = usePreview();
  const [detailAdditor, setDetailAdditor] = useState(false);
  const [detailEditor, setDetailEditor] = useState(false);
  const router = useRouter();
  const { slug } = router.query;

  const { loading, error, data, refetch } = useQuery(Q_BUSINESS_CONTACT, {
    variables: {
      slug,
    },
  });

  useEffect(() => {
    onLayoutUpdate();
  }, [detailEditor]);

  const [addBusinessContact, addBusinessProps] = useMutation<BusinessEditResponse>(
    M_BUSINESS_CONTACT_EDIT,
    {
      onCompleted(data: any) {
        if (data.editBusiness) {
          setDetailAdditor(false);
          refetch();
        }
      },
      onError(error: any) {
        console.log('Error: ', error.message);
      },
    }
  );

  const handleDetailAdditor = () => {
    setDetailAdditor(true);
  };

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  const handleDetailSubmit = (values: Location) => {
    const location = {
      label: values.label,
      country: values.country,
      state: values.state,
      city: values.city,
      description: values.description,
      persons: values.persons,
    };

    let locations = [];
    // remove "__typename" key
    if (contact && contact.locations) {
      const oldLocations = contact.locations.map((location) => {
        return {
          label: location.label,
          country: location.country,
          state: location.state,
          city: location.city,
          description: location.description,
          persons: location.persons.map((person) => {
            return {
              firstName: person.firstName,
              lastName: person.lastName,
              title: person.title,
              phone: person.phone,
              email: person.email,
            };
          }),
        };
      });
      locations = [...oldLocations, location];
    } else {
      locations = [location];
    }

    addBusinessContact({
      variables: {
        id: businessId, //user.business.id,
        locations,
      },
    });
  };

  const [editBusinessContact, editBusinessProps] = useMutation<BusinessEditResponse>(
    M_BUSINESS_CONTACT_EDIT,
    {
      onCompleted(data: any) {
        if (data.editBusiness) {
          setDetailEditor(false);
          refetch();
        }
      },
      onError(error: any) {
        console.log('Error: ', error.message);
      },
    }
  );

  const handleDetailEditSubmit = (values: ContactInput) => {
    // remove "__typename" key
    const updatedLocations = values.locations.map((location) => {
      return {
        label: location.label,
        country: location.country,
        state: location.state,
        city: location.city,
        description: location.description,
        persons: location.persons.map((person) => {
          return {
            firstName: person.firstName,
            lastName: person.lastName,
            title: person.title,
            phone: person.phone,
            email: person.email,
          };
        }),
      };
    });

    editBusinessContact({
      variables: {
        id: businessId, //user.business.id,
        locations: updatedLocations,
      },
    });
  };

  if (loading || error) {
    return (
      <div className={`full-width row ${styles.container}`}>
        <section className="inner-width inner-width-padding column center">
          {error && <p className="error">{error.message}</p>}
        </section>
      </div>
    );
  }

  const isOwner = user && user.id === data.business.data.ownerId;
  const isAdmin =
    user && user.data.adminRole && user.data.adminRole.toUpperCase() === 'ADMIN';
  const isEditable = isOwner || isAdmin;

  const { contact } = data.business.data;

  const renderEditLocations = (locations) => {
    return (
      <Formik
        initialValues={{ locations: locations }}
        validationSchema={ValidationSchema}
        onSubmit={handleDetailEditSubmit}
      >
        {({ values }) => (
          <Form
            className={`inner-width inner-width-padding ${styles.form} ${styles.location}`}
          >
            <FieldArray
              name="locations"
              render={(arrayHelpers) => (
                <>
                  <div className={styles.locations}>
                    {values.locations &&
                      values.locations.length > 0 &&
                      values.locations.map((location, index) => (
                        <div key={index} className={styles.group}>
                          <div className={styles.remove}>
                            <SettingsButton
                              type={SettingsType.DELETE}
                              onClick={() => arrayHelpers.remove(index)}
                              light
                            />
                            <p>remove location</p>
                          </div>
                          <FormTextField
                            name={`locations.${index}.label`}
                            label="Location Name (ie. Headquarters)"
                            size="large"
                            usePlaceholder
                            light
                          />
                          <FormTextField
                            name={`locations.${index}.city`}
                            label="City"
                            usePlaceholder
                            light
                          />
                          <FormTextField
                            name={`locations.${index}.state`}
                            label="State (optional)"
                            usePlaceholder
                            light
                          />
                          <FormTextField
                            name={`locations.${index}.country`}
                            label="Country"
                            usePlaceholder
                            light
                          />
                          <FormTextField
                            name={`locations.${index}.description`}
                            label="Description"
                            isBox={true}
                            showCounter={true}
                            usePlaceholder
                            light
                          />

                          <div>
                            <FormObjectArrayField
                              name={`locations.${index}.persons`}
                              label="Contact person(s)"
                              addLabel="Add Another Contact"
                              items={contactObjects}
                              maxLength={4}
                              light
                            />
                          </div>
                          <div className={styles.seperator} />
                        </div>
                      ))}
                  </div>
                  <div className={styles.formbuttons}>
                    <button type="submit" className="button secondary">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn-inline small"
                      onClick={() => setDetailEditor(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  {editBusinessProps.error && (
                    <div className="full-width column center">
                      <p className="error">{editBusinessProps.error.message}</p>
                    </div>
                  )}
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  };

  const renderLocation = (item: Location, index: number) => {
    return (
      <div
        key={index}
        className={`inner-width inner-width-padding ${styles.location}`}
      >
        <article>
          {item.persons &&
            item.persons.length > 0 &&
            item.persons[0].firstName.length > 0 && (
              <div className={styles.info}>
                <h3 className={styles.label}>Contact</h3>
                <div className={styles.people}>
                  {item.persons.map((item, index) => (
                    <div key={index} className={styles.person}>
                      <div className={styles.position}>
                        <p className="font-medium">
                          {item.firstName} {item.lastName}
                        </p>
                        <p className="font-light">{item.title}</p>
                      </div>
                      <div>
                        <p className="font-light">{item.email}</p>
                        <p className="font-light">{item.phone}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          <div className={styles.info}>
            <h3 className={styles.label}>{item.label}</h3>
            <p className={`font-medium ${styles.address}`}>
              {item.city}, {item.state ? `${item.state},` : ''} {item.country}
            </p>
            <p className={styles.description}>{item.description}</p>
          </div>
        </article>
        {isEditable && detailAdditor === false && !previewMode && (
          <EditorIcon onClick={handleDetailEditor} />
        )}
      </div>
    );
  };

  const renderAddLocation = () => {
    // if owner and wants to add a locations show add location form else
    // show dummy data of what location data would look like
    return (
      <>
        {detailAdditor && isEditable ? (
          <Formik
            initialValues={{
              label: '',
              country: '',
              state: '',
              city: '',
              description: '',
              persons: [
                {
                  firstName: '',
                  lastName: '',
                  title: '',
                  phone: '',
                  email: '',
                },
              ],
            }}
            validationSchema={ValidationSchema}
            onSubmit={handleDetailSubmit}
          >
            {({ values }) => (
              <Form
                className={`inner-width inner-width-padding ${styles.form} ${styles.location}`}
              >
                <FormTextField
                  name="label"
                  label="Location Name (ie. Headquarters)"
                  size="large"
                  usePlaceholder
                  light
                />
                <FormTextField name="city" label="City" usePlaceholder light />
                <FormTextField
                  name="state"
                  label="State (optional)"
                  usePlaceholder
                  light
                />
                <FormTextField name="country" label="Country" usePlaceholder light />
                <FormTextField
                  name="description"
                  label="Add Description"
                  isBox={true}
                  showCounter={true}
                  light
                />

                <FormObjectArrayField
                  name={`persons`}
                  label=""
                  addLabel="Add Another Contact"
                  items={contactObjects}
                  maxLength={4}
                  light
                />
                <div className={styles.formbuttons}>
                  <button type="submit" className="button secondary">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-inline small"
                    onClick={() => setDetailAdditor(false)}
                  >
                    Cancel
                  </button>
                </div>
                {addBusinessProps.error && (
                  <div className="full-width column center">
                    <p className="error">{addBusinessProps.error.message}</p>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <div className={`inner-width inner-width-padding ${styles.location}`}>
            {!detailEditor && (
              <article>
                {!isEditable && (
                  <div className="center text-center">
                    <p className="font-medium">{doc.data.contact_no_info}</p>
                  </div>
                )}
                {/* }) : (
                  <div className="center text-center">
                    <p className="font-medium">{doc.data.contact_no_info}</p>
                  </div>
                  <>
                    <div className={styles.info}>
                      <h3 className={styles.label}>Contact</h3>
                      <div className={styles.people}>
                        {DUMMY_CONTACT.persons.map((item, index) => (
                          <div key={index} className={styles.person}>
                            <div className={styles.position}>
                              <p className="font-medium">
                                {item.firstName} {item.lastName}
                              </p>
                              <p className="font-light">{item.title}</p>
                            </div>
                            <div>
                              <p className="font-light">{item.email}</p>
                              <p className="font-light">{item.phone}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={styles.info}>
                      <h3 className={styles.label}>{DUMMY_CONTACT.label}</h3>
                      <p className={`font-medium ${styles.address}`}>
                        {DUMMY_CONTACT.city},{' '}
                        {DUMMY_CONTACT.state ? `${DUMMY_CONTACT.state},` : ''}{' '}
                        {DUMMY_CONTACT.country}
                      </p>
                      <p className={styles.description}>
                        {DUMMY_CONTACT.description}
                      </p>
                    </div>
                  </>
                )} */}
              </article>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isEditable && detailEditor && renderEditLocations(contact.locations)}

      {data && contact && detailAdditor === false && detailEditor === false
        ? contact.locations.map((item, index) => renderLocation(item, index))
        : renderAddLocation()}

      {isEditable && !detailAdditor && !detailEditor && !previewMode && (
        <div className={`inner-width ${styles.location}`}>
          <button className="button secondary" onClick={handleDetailAdditor}>
            {doc.data.contact_add_button}
          </button>
        </div>
      )}
    </>
  );
};

export default BusinessContact;

{
  /* <p>Add contact details</p>
<EditorIcon onClick={handleDetailAdditor} /> */
}
