import React from 'react';
import CrossIcon from './CrossIcon';
import styles from './buttons.module.sass';

type Props = {
  label?: string;
  light?: boolean;
  onClick: () => void;
};

const ButtonCancel = ({ label, light, onClick }: Props) => (
  <button
    type="button"
    className={`${styles.button} ${light ? styles.light : ''}`}
    onClick={onClick}
  >
    <CrossIcon />
    <span className={styles.label}>{label ? label : 'Cancel'}</span>
  </button>
);

export default ButtonCancel;
