//@flow
import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/react-hooks';
import { Q_BUSINESS_SPECS } from '../../../graphql/business';
import { useAuth, usePreview } from '../../../hooks';
import { PrismicBusinessDetail } from '../../../types/prismic';
import SpecsOwnership from './SpecsOwnership';
import SpecsFinances from './SpecsFinances';
import SpecsProduction from './SpecsProduction';
import SpecsRetailers from './SpecsRetailers';

import styles from './specs.module.sass';

type Props = {
  businessId: string;
  doc: PrismicBusinessDetail;
  onLayoutUpdate: () => void;
};

const BusinessSpecs = ({ businessId, onLayoutUpdate }: Props) => {
  const { user } = useAuth();
  const { previewMode } = usePreview();
  const router = useRouter();
  const { slug } = router.query;

  const { loading, error, data, refetch } = useQuery(Q_BUSINESS_SPECS, {
    variables: {
      slug,
    },
  });

  if (loading || error) {
    return (
      <div className={`full-width row ${styles.container}`}>
        <section className="inner-width inner-width-padding column center">
          {error && <p className="error">{error.message}</p>}
        </section>
      </div>
    );
  }

  const handleBusinessUpdate = () => {
    refetch();
  };

  const isOwner = user && user.id === data.business.data.ownerId;
  const isAdmin =
    user && user.data.adminRole && user.data.adminRole.toUpperCase() === 'ADMIN';
  const isEditable = isOwner || isAdmin;

  const { name, specs } = data.business.data;

  return (
    <div className={`${styles.container}`}>
      <article className={`inner-width inner-width-padding ${styles.inner}`}>
        <div className={styles.column}>
          <SpecsOwnership
            previewMode={previewMode}
            businessId={businessId}
            businessName={name}
            data={specs}
            isEditable={isEditable}
            onUpdate={handleBusinessUpdate}
            onLayoutUpdate={onLayoutUpdate}
          />
          <div className={styles.info}>
            <SpecsProduction
              previewMode={previewMode}
              businessId={businessId}
              data={specs}
              isEditable={isEditable}
              onUpdate={handleBusinessUpdate}
              onLayoutUpdate={onLayoutUpdate}
            />
          </div>
          <SpecsFinances
            previewMode={previewMode}
            businessId={businessId}
            data={specs}
            isEditable={isEditable}
            onUpdate={handleBusinessUpdate}
            onLayoutUpdate={onLayoutUpdate}
          />
          <SpecsRetailers
            previewMode={previewMode}
            businessId={businessId}
            data={specs}
            isEditable={isEditable}
            onUpdate={handleBusinessUpdate}
            onLayoutUpdate={onLayoutUpdate}
          />
        </div>
      </article>
    </div>
  );
};

export default BusinessSpecs;
