//@flow
import React from 'react';
import { BusinessRecord } from '../../../types/business';
import BusinessRecommendationThumb from './BusinessRecommendationThumb';
import styles from './business-recommendation.module.sass';

type Props = {
  label: string;
  data: BusinessRecord[];
};

const BusinessRecommendations = ({ label, data }: Props) => {
  return (
    <div className="full-width column center">
      <h4>{label}</h4>
      <div className={styles.recommends} data-length={data.length}>
        {data.map((item, index) => (
          <BusinessRecommendationThumb key={`business-${index}`} business={item} />
        ))}
      </div>
    </div>
  );
};

export default BusinessRecommendations;
