//@flow
import React, { useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { M_BUSINESS_ABOUT_EDIT } from '../../graphql/business';
import { BusinessEditAbout, BusinessEditResponse } from '../../types/business';
import EditorCameraIcon from '../icons/EditorCameraIcon';
import { GalleryImage } from '.';
import LoadingIndicator from '../base/LoadingIndicator';
import styles from './business-products.module.sass';

type Props = {
  previewMode: boolean;
  businessId: string;
  data: any;
  isEditable: boolean;
  onUpdate: () => void;
};

const positions = [
  'rotate(-3deg) translate3d(-20%, 15%, 0) scale3d(0.8,0.8,1)',
  'rotate(5deg) translate3d(25%, 10%, 0) scale3d(0.8,0.8,1)',
  'rotate(3deg) translate3d(35%, 5%, 0) scale3d(0.8,0.8,1)',
];

const BusinessProducts = ({
  previewMode,
  businessId,
  data,
  isEditable,
  onUpdate,
}: Props) => {
  // const { user } = useAuth();
  const [selectedImage, setSelectedImage] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const formikRef = useRef<FormikProps<any>>();
  const [showLoading, setShowLoading] = useState(false);

  const [editBusinessProducts, { loading, error }] = useMutation<
    BusinessEditResponse,
    BusinessEditAbout
  >(M_BUSINESS_ABOUT_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        setEditMode(false);
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  const getValuesForImage = (values, index) => {
    let image = null;
    let keepValue = true;
    if (!data && values[`image-${index}`].length) {
      image = values[`image-${index}`];
      keepValue = false;
    } else if (
      values[`image-${index}`] &&
      data[index] &&
      data[index].image &&
      values[`image-${index}`] !== data[index].image
    ) {
      image = values[`image-${index}`];
      keepValue = false;
    }

    return {
      image,
      keepValue,
    };
  };

  const handleImagesSubmit = (values) => {
    const valueOne = getValuesForImage(values, 0);
    const valueTwo = getValuesForImage(values, 1);
    const valueThree = getValuesForImage(values, 2);

    editBusinessProducts({
      variables: {
        id: businessId, //user.business.id,
        products: [
          {
            image: valueOne.image,
            label: '',
            keepOldValue: valueOne.keepValue,
          },
          {
            image: valueTwo.image,
            label: '',
            keepOldValue: valueTwo.keepValue,
          },
          {
            image: valueThree.image,
            label: '',
            keepOldValue: valueThree.keepValue,
          },
        ],
      },
    });
  };

  const handleImageUpdate = (index, image) => {
    if (formikRef && formikRef.current)
      formikRef.current.setFieldValue(`image-${index}`, image);
  };

  const handleEditor = () => {
    setShowLoading(true);
    setTimeout(() => {
      setEditMode(true);
    }, 100);

    setTimeout(() => {
      setShowLoading(false);
    }, 1200);
  };

  const handleImagesCancel = () => {
    setEditMode(false);
  };

  const onPressImage = () => {
    if (selectedImage === 2) {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  };

  const renderImages = () => {
    if (!isEditable && !data?.length) return null;

    const items = [];
    for (let i = 0; i < 3; i += 1) {
      const itemData = data && i < data.length ? data[i] : { image: '', label: '' };
      if (
        itemData?.image?.length ||
        (isEditable && !previewMode) ||
        (editMode && !previewMode)
      ) {
        const item = (
          <GalleryImage
            key={`${i}`}
            imageIndex={i}
            editMode={editMode}
            item={itemData}
            selected={i === selectedImage}
            position={positions[i]}
            length={3}
            zindex={i >= selectedImage ? 3 - i : 1}
            onPressImage={onPressImage}
            updateImage={(image) => handleImageUpdate(i, image)}
          />
        );
        items.push(item);
      }
    }

    return items;
  };

  return (
    <div className={styles.stack}>
      <div className={`${styles.container} ${editMode ? styles.editmode : ''}`}>
        {editMode && !previewMode ? (
          <Formik
            innerRef={formikRef}
            initialValues={{
              'image-0': data && data[0] && data[0].image ? data[0].image : '',
              'image-1': data && data[1] && data[1].image ? data[1].image : '',
              'image-2': data && data[2] && data[2].image ? data[2].image : '',
            }}
            onSubmit={handleImagesSubmit}
          >
            <Form className={styles.form}>
              <div className={styles.editor}>{renderImages()}</div>
              <p className={`full-width font-small ${styles.size}`}>
                recommended size: 640x640
              </p>
              <div className={styles.formbuttons}>
                <button
                  type="button"
                  className="button-inline small"
                  onClick={handleImagesCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="button primary small">
                  Save
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  <p className="error">{error.message}</p>
                </div>
              )}
            </Form>
          </Formik>
        ) : (
          <div className={styles.images}>{renderImages()}</div>
        )}
        {isEditable && !editMode && !previewMode && (
          <button className={styles.edit} onClick={handleEditor}>
            <EditorCameraIcon />
          </button>
        )}
      </div>
      {(showLoading || loading) && <LoadingIndicator />}
    </div>
  );
};

export default BusinessProducts;
