import React from 'react';
import styles from './editor-icon.module.sass';

const EditorCameraIcon = () => {
  return (
    <svg
      className={styles.camera}
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="40"
      viewBox="0 0 50 40"
    >
      <path
        className={styles.icon}
        d="M2.4996,37.3714 L47.4996,37.3714 L47.4996,9.7734 L39.4846,9.7734 C38.9626,9.7734 38.4956,9.4494 38.3136,8.9594 L35.9066,2.4994 L14.0936,2.4994 L11.6866,8.9594 C11.5046,9.4494 11.0366,9.7734 10.5156,9.7734 L2.4996,9.7734 L2.4996,37.3714 Z M48.7496,39.8714 L1.2496,39.8714 C0.5596,39.8714 -0.0004,39.3114 -0.0004,38.6214 L-0.0004,8.5234 C-0.0004,7.8324 0.5596,7.2734 1.2496,7.2734 L9.6466,7.2734 L12.0536,0.8134 C12.2356,0.3244 12.7026,-0.0006 13.2256,-0.0006 L36.7746,-0.0006 C37.2966,-0.0006 37.7636,0.3244 37.9456,0.8134 L40.3536,7.2734 L48.7496,7.2734 C49.4396,7.2734 49.9996,7.8324 49.9996,8.5234 L49.9996,38.6214 C49.9996,39.3114 49.4396,39.8714 48.7496,39.8714 L48.7496,39.8714 Z"
      />
      <path
        className={styles.icon}
        d="M24.9996,13.554 C20.2026,13.554 16.3006,17.457 16.3006,22.254 C16.3006,27.051 20.2026,30.953 24.9996,30.953 C29.7976,30.953 33.6996,27.051 33.6996,22.254 C33.6996,17.457 29.7976,13.554 24.9996,13.554 M24.9996,33.453 C18.8246,33.453 13.8006,28.429 13.8006,22.254 C13.8006,16.078 18.8246,11.054 24.9996,11.054 C31.1756,11.054 36.1996,16.078 36.1996,22.254 C36.1996,28.429 31.1756,33.453 24.9996,33.453"
      />
    </svg>
  );
};

export default EditorCameraIcon;
