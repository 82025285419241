import React from 'react';
import styles from './settings.module.sass';

export enum SettingsType {
  EDIT,
  SHARE,
  DELETE,
  ADD,
}

type Props = {
  type: SettingsType;
  disabled?: boolean;
  light?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SettingsButton = ({ type, disabled, light, onClick }: Props) => {
  const renderIcon = () => {
    let icon = null;
    switch (type) {
      case SettingsType.EDIT:
        icon = (
          <svg
            className={styles.container}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(8.294 8.294)">
              <path
                className={styles.fill}
                d="M13.559,25.513l6.451-6.451,2.819,2.819-6.451,6.451Z"
                transform="translate(-12.038 -16.784)"
              />
              <path
                className={styles.fill}
                d="M64.27,4.253,66.023,2.5l2.819,2.819L67.089,7.072Z"
                transform="translate(-55.773 -2.5)"
              />
              <path
                className={styles.fill}
                d="M3.269,70,2.5,73.588l3.587-.769.227-.226L3.5,69.774Z"
                transform="translate(-2.5 -60.519)"
              />
            </g>
          </svg>
        );
        break;
      case SettingsType.SHARE:
        icon = (
          <svg
            className={styles.container}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <path
              className={styles.fill}
              d="M24.279,11.3,6,18.21l5.612,1.725,1.763,5.544,2.881-2.724,4.751,3.719ZM12.126,19.754l-.113.036,10.493-6.727-8.594,7.856-.34,3.381Z"
              transform="translate(-1.876 -3.921)"
            />
          </svg>
        );
        break;
      case SettingsType.DELETE:
        icon = (
          <svg
            className={styles.container}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g transform="translate(4.675 6.947)">
              <path
                className={styles.fill}
                d="M17.46,2.937H14.666V1.825a1.7,1.7,0,0,0-1.7-1.7H7.68a1.7,1.7,0,0,0-1.7,1.7V2.937H3.189a.4.4,0,0,0,0,.793h.928a.394.394,0,0,0-.008.136l1.45,11.765a.4.4,0,0,0,.393.348H14.6a.4.4,0,0,0,.393-.348l1.45-11.765a.39.39,0,0,0-.008-.136H17.46a.4.4,0,0,0,0-.793ZM6.776,1.825a.906.906,0,0,1,.9-.9h5.288a.906.906,0,0,1,.9.9V2.937h-7.1Zm8.881,1.944L14.249,15.186H6.3L4.9,3.769a.394.394,0,0,0-.009-.039H15.665A.394.394,0,0,0,15.656,3.769Z"
              />
              <path
                className={styles.fill}
                d="M37.567,42.031a.4.4,0,0,0,.4-.4V33.846a.4.4,0,0,0-.793,0v7.788A.4.4,0,0,0,37.567,42.031Z"
                transform="translate(-28.929 -28.041)"
              />
              <path
                className={styles.fill}
                d="M58.838,42.031a.4.4,0,0,0,.4-.4V33.846a.4.4,0,0,0-.793,0v7.788A.4.4,0,0,0,58.838,42.031Z"
                transform="translate(-46.828 -28.041)"
              />
            </g>
          </svg>
        );
        break;
      case SettingsType.ADD:
        icon = (
          <svg
            className={styles.container}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <path
              className={styles.fill}
              d="M19.742,12a1.019,1.019,0,0,0-1.019,1.019v5.7h-5.7a1.019,1.019,0,1,0,0,2.037h5.7v5.7a1.019,1.019,0,0,0,2.037,0v-5.7h5.7a1.019,1.019,0,0,0,0-2.037h-5.7v-5.7A1.019,1.019,0,0,0,19.742,12Z"
              transform="translate(-5 -5)"
            />
          </svg>
        );
        break;
      default:
        icon = null;
        break;
    }

    return icon;
  };

  return (
    <button
      className={`${styles.wrapper} ${disabled ? styles.disabled : ''} ${
        light ? styles.light : ''
      }`}
      // disabled={disabled ? disabled : false}
      onClick={onClick ? onClick : null}
    >
      {renderIcon()}
    </button>
  );
};

export default SettingsButton;
