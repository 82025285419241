//@flow
import React, { useEffect, useRef, useState } from 'react';
import Compressor from 'compressorjs';
import { Field, Form, Formik } from 'formik';
import { ButtonCancel, ButtonConfirm } from '../buttons';
import { EditorCameraIcon } from '../icons';
import styles from './header-image-form.module.sass';

type Props = {
  initialImage: {
    image: string;
    label?: string;
  };
  index: number;
  onSubmit: (any) => void;
};

const HeaderImageForm = ({ initialImage, index, onSubmit }: Props) => {
  const imageInputRef = useRef<HTMLInputElement>();
  const [imagePreview, setImagePreview] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    if (!initialImage) return;

    if (currentImage !== initialImage.image) {
      setCurrentImage(initialImage.image);
      setImagePreview(null);
    }
  }, [initialImage]);

  const handleImageChange = () => {
    const reader = new FileReader();
    let inputField = null;
    const minWidth = 640;
    const minHeight = 640;
    const maxWidth = 1280;
    const maxHeight = 1280;
    const targetWidth = 1280;

    inputField =
      imageInputRef && imageInputRef.current ? imageInputRef.current : null;
    if (inputField) {
      const image = inputField.files[0];
      new Compressor(image, {
        quality: 0.85,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        width: targetWidth,
        success: (result) => {
          reader.readAsDataURL(result);
        },
      });
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      // reader.readAsDataURL();
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${
          imagePreview ? imagePreview : initialImage ? initialImage.image : ''
        })`,
      }}
    >
      <Formik
        initialValues={{
          image: '',
        }}
        onSubmit={() => onSubmit(imagePreview)} //() => handleImageSubmit(1)}
      >
        <Form className={styles.form}>
          {!imagePreview && (
            <label className={styles.formupload} htmlFor={`image-${index}`}>
              <EditorCameraIcon />
            </label>
          )}
          <Field
            innerRef={imageInputRef}
            type="file"
            className="sr"
            id={`image-${index}`}
            name="image"
            onChange={handleImageChange}
          />
          {imagePreview && (
            <div className={styles.formbuttons}>
              <ButtonCancel light onClick={() => setImagePreview(null)} />
              <ButtonConfirm light />
            </div>
          )}
        </Form>
      </Formik>
    </div>
  );
};

export default HeaderImageForm;
