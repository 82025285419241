//@flow
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import { M_BUSINESS_SPECS_EDIT } from '../../../graphql/business';
import { BusinessEditResponse } from '../../../types/business';
import EditorIcon from '../../icons/EditorIcon';
import FormBooleanField from '../../form/ui/FormBooleanField';
import FormSelectField from '../../form/ui/FormSelectField';
import FormTextField from '../../form/ui/FormTextField';
import styles from './business-specs.module.sass';
import {
  CompanyProvidesOptions,
  ProductsMadeOptions,
} from '../../../helpers/constants';
import businessFormModel from '../../registration/business/model/businessFormModel';
import { useCategories } from '../../../hooks';
import { cleanDataFromTypes } from '../../../helpers/dataCleaning';

type Props = {
  previewMode: boolean;
  isEditable: boolean;
  businessId: string;
  data: any;
  onUpdate: () => void;
  onLayoutUpdate: () => void;
};

const SpecsProduction = ({
  previewMode,
  isEditable,
  businessId,
  data,
  onUpdate,
  onLayoutUpdate,
}: Props) => {
  const { categories } = useCategories();
  // const { user } = useAuth();
  const [detailEditor, setDetailEditor] = useState(false);

  const [
    editBusinessProduction,
    { loading, error },
  ] = useMutation<BusinessEditResponse>(M_BUSINESS_SPECS_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  useEffect(() => {
    onLayoutUpdate();
  }, [detailEditor]);

  useEffect(() => {
    if (data) setDetailEditor(false);
  }, [data]);

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  const handleFinancesSubmit = (values) => {
    editBusinessProduction(
      cleanDataFromTypes({
        variables: {
          id: businessId,
          ownership: {
            blackOwned: data.ownership.blackOwned,
            blackFounded: data.ownership.blackFounded,
            blackFronted: data.ownership.blackFronted,
            indigenousOwned: data.ownership.indigenousOwned,
            majorityWomenOwned: data.ownership.majorityWomenOwned,
          },
          production: {
            hasFactor: values.production.hasFactor,
            capacity: values.production.capacity,
            companyProvide: values.production.companyProvide,
            services: values.production.services,
            servicesOther: values.production.servicesOther,
            products: values.production.products,
            ethicallySustainableMade: values.production.ethicallySustainableMade,
          },
          retailers: {
            current: data.retailers.current,
            interests: data.retailers.interests,
          },
          finances: {
            wantsInvestorsFunding: data.finances.wantsInvestorsFunding,
          },
        },
      })
    );
  };

  const showProducts = (values) =>
    values.production.companyProvide?.value === 'products' ||
    values.production.companyProvide?.value === 'both';

  const showServices = (values) =>
    values.production.companyProvide?.value === 'services' ||
    values.production.companyProvide?.value === 'both';

  const renderProductsFields = (values, categories) => {
    return (
      <>
        <FormSelectField
          isMulti={true}
          name="production.products"
          label={businessFormModel.formField.products.label}
          options={categories && categories.data.products}
          light
        />
        <FormSelectField
          name="production.ethicallySustainableMade"
          label={businessFormModel.formField.ethicallySustainableMade.label}
          options={ProductsMadeOptions}
          light
        />
        <FormBooleanField
          name="production.hasFactor"
          label={businessFormModel.formField.hasFactor.label}
          selected={values.production.hasFactor}
        />
        <FormTextField
          name="production.capacity"
          label={businessFormModel.formField.capacity.label}
          isBox={true}
          showCounter={true}
          light
        />
      </>
    );
  };

  const renderServicesFields = (values, categories) => {
    return (
      <>
        <FormSelectField
          isMulti={true}
          name="production.services"
          label={businessFormModel.formField.services.label}
          options={categories.data.services}
          light
        />
        {values.production.services.find((el) => el.value === 'other') && (
          <FormTextField
            name='production.servicesOther'
            label={businessFormModel.formField.servicesOther.label}
            light
          />
        )}
      </>
    );
  };

  const setupInitialValues = (data) => {
    return cleanDataFromTypes({
      production: {
        companyProvide:
          data.production && data.production.companyProvide
            ? data.production.companyProvide
            : '',
        ethicallySustainableMade:
          data.production && data.production.ethicallySustainableMade
            ? data.production.ethicallySustainableMade
            : false,
        hasFactor:
          data.production && data.production.hasFactor
            ? data.production.hasFactor
            : false,
        products:
          data.production && data.production.products
            ? data.production.products
            : false,

        capacity:
          data.production && data.production.capacity
            ? data.production.capacity
            : '',

        // service specific values
        services:
          data.production && data.production.services
            ? data.production.services
            : false,

        servicesOther:
          data.production && data.production.servicesOther
            ? data.production.servicesOther
            : '',
      },
    });
  };

  return (
    <div className={styles.info}>
      <h3>Production Services</h3>
      {isEditable && detailEditor === true && !previewMode ? (
        <Formik
          initialValues={setupInitialValues(data)}
          onSubmit={handleFinancesSubmit}
        >
          {({ values }) => (
            <Form className={styles.form}>
              <div className="full-width column center text-center">
                <FormSelectField
                  name="production.companyProvide"
                  label={businessFormModel.formField.companyProvide.label}
                  options={CompanyProvidesOptions}
                  light
                />
                {showProducts(values) && renderProductsFields(values, categories)}
                {showServices(values) && renderServicesFields(values, categories)}
              </div>
              <div className={`${styles.formbuttons} ${styles.end}`}>
                <button
                  type="button"
                  className="button-inline small"
                  onClick={() => setDetailEditor(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="button secondary small">
                  Save
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  <p className="error">{error.message}</p>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <article className="column center">
            {/*{data.production.ethicallySustainable && <p>Ethical/Sustainable</p>}*/}
            {data.production.hasFactor && <p>Currently works with a factory</p>}
          </article>
          {/*<article className="column center">*/}
          {/*  <h5>Production Products</h5>*/}
          {/*  <p>{data.production.coreProducts}</p>*/}
          {/*</article>*/}
          <article className="column center">
            <h5>Production Capacity</h5>
            <p>{data.production.capacity}</p>
          </article>
          {isEditable && detailEditor === false && !previewMode && (
            <article className="column center">
              <EditorIcon onClick={handleDetailEditor} />
            </article>
          )}
        </>
      )}
    </div>
  );
};

export default SpecsProduction;
