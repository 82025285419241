import * as React from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import Select, { components } from 'react-select';
import ErrorMessageComponent from '../ErrorMessageComponent';
import { SelectInput } from '../../../types/business';
import { isArray } from 'lodash';

interface Props {
  name: string;
  label: string;
  isMulti?: boolean;
  isLarge?: boolean;
  options: any;
  light?: boolean;
}

const DropdownIndicator = (
  // @ts-ignore
  props: React.ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src="/images/dropdown-arrow.svg" />
    </components.DropdownIndicator>
  );
};

const DropdownIndicatorLight = (
  // @ts-ignore
  props: React.ElementConfig<typeof components.DropdownIndicatorLight>
) => {
  return (
    <components.DropdownIndicatorLight {...props}>
      <img src="/images/dropdown-arrow.svg" />
    </components.DropdownIndicatorLight>
  );
};

type SelectedValue = undefined | SelectInput | SelectInput[];

const FormSelectField = ({
  name,
  label,
  isLarge,
  options,
  light,
  ...props
}: Props) => {
  const [field] = useField(name);
  const { setFieldValue, validateField } = useFormikContext();
  const { value } = field;

  const selectedValue = value as SelectedValue;
  const handleChange = (value) => {
    setFieldValue(name, value);
    validateField(name);
  };

  const defaultValue = props.isMulti
    ? options.filter(
        (option) =>
          selectedValue &&
          isArray(selectedValue) &&
          selectedValue.find((v) => v.value === option.value)
      )
    : options.find(
        (option) =>
          selectedValue && (selectedValue as SelectInput).value === option.value
      );

  const indicator = light ? DropdownIndicator : DropdownIndicatorLight;

  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <Select
        {...field}
        value={defaultValue ? defaultValue : ''}
        id={name}
        className={`dropdown-select ${isLarge ? 'large' : ''} 
          ${props.isMulti ? 'is-multi' : ''} 
          ${light ? 'light' : ''} 
          ${
            props.isMulti && field && field.value && field.value.length
              ? 'has-value'
              : ''
          }`}
        classNamePrefix="dropdown-select"
        components={{ indicator }}
        options={options}
        isMulti={props.isMulti}
        isSearchable={!isLarge}
        onChange={handleChange}
        onBlur={field.onBlur}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <ErrorMessageComponent error={msg} />}
      />
    </>
  );
};

export default FormSelectField;
