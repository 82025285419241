//@flow
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { Field, Form, Formik } from 'formik';
import Compressor from 'compressorjs';
import { M_BUSINESS_HEADER, M_BUSINESS_ABOUT_EDIT } from '../../graphql/business';
import {
  AboutInput,
  BusinessEditHeader,
  BusinessEditAbout,
  BusinessEditResponse,
} from '../../types/business';
import FormTextField from '../form/ui/FormTextField';
import { EditorCameraIcon, EditorIcon } from '../icons';
import { ButtonCancel, ButtonConfirm } from '../buttons';
import styles from './business-about.module.sass';
import InstagramHandle from './InstagramHandle';

type Props = {
  previewMode: boolean;
  isEditable: boolean;
  businessId: string;
  data: AboutInput;
  showInstagram: boolean;
  headerImage: string;
  website: string;
  onUpdate: () => void;
  copy: {
    about_website_button: string;
    about_editor_additional_hint: string;
    about_editor_hint: string;
    about_editor_link_hint: string;
    about_link_header: string;
    form_story_label: string;
    form_website_label: string;
    form_instagram_label: string;
  };
};

const ValidationSchema = Yup.object().shape({
  story: Yup.string(),
  website: Yup.string()
    .url('Please enter a valid url, starting with http:// | https://')
    .required('You must enter a url to publish your page'),
  instagram: Yup.string(),
});

const BusinessAbout = ({
  previewMode,
  isEditable,
  businessId,
  data,
  showInstagram,
  headerImage,
  website,
  onUpdate,
  copy,
}: Props) => {
  const imageInputRef = useRef<HTMLInputElement>();
  const [detailEditor, setDetailEditor] = useState(false);
  // const [imageEditor, setImageEditor] = useState(false);
  const [headerImagePreview, setHeaderImagePreview] = useState(null);

  const [editBusinessHeader, headerGraphProps] = useMutation<
    BusinessEditResponse,
    BusinessEditHeader
  >(M_BUSINESS_HEADER, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  useEffect(() => {
    setHeaderImagePreview(null);
    // setImageEditor(false);
  }, [headerImage]);

  const [editBusinessAbout, headerAboutProps] = useMutation<
    BusinessEditResponse,
    BusinessEditAbout
  >(M_BUSINESS_ABOUT_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) {
        setDetailEditor(false);
        onUpdate();
      }
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  // const handleImageEditor = () => {
  //   setImageEditor(true);
  // };

  const handleImageCancel = () => {
    setHeaderImagePreview(null);
    // setImageEditor(false);
  };

  const handleImageChange = () => {
    const reader = new FileReader();
    const image = imageInputRef.current.files[0];
    if (!image) return;
    new Compressor(image, {
      quality: 0.85,
      minWidth: 480,
      minHeight: 480,
      maxWidth: 640,
      maxHeight: 640,
      width: 640,
      success: (result) => {
        reader.readAsDataURL(result);
      },
    });

    // reader.readAsDataURL(image);

    reader.onload = (e) => {
      setHeaderImagePreview(e.target.result);
    };
  };

  const handleImageSubmit = () => {
    if (!headerImagePreview.length) {
      // setImageEditor(false);
      return;
    }

    editBusinessHeader({
      variables: {
        id: businessId, //user.business.id,
        headerImage: headerImagePreview,
      },
    });
  };

  const handleDetailSubmit = (values) => {
    editBusinessAbout({
      variables: {
        id: businessId, //user.business.id,
        website: values.website,
        instagram: values.instagram,
        story: values.story,
      },
    });
  };

  // console.log(headerGraphProps);

  return (
    <article className={`full-width ${styles.container}`}>
      <div className={styles.desc}>
        {/* {isEditable ? (
          
        ) : (
          <div className={styles.circle}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url('${headerImage}')` }}
            />
          </div>
        )} */}
        {isEditable && !previewMode && (
          <div className={`column start ${styles.detail} ${styles.form}`}>
            <div className={styles.thumb}>
              <Formik
                initialValues={{
                  headerImage: '',
                }}
                onSubmit={handleImageSubmit}
              >
                <Form>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${
                        headerImagePreview ? headerImagePreview : headerImage
                      })`,
                    }}
                  >
                    {!headerImagePreview && !previewMode && (
                      <label className={styles.formupload} htmlFor="headerImage">
                        <EditorCameraIcon />
                      </label>
                    )}
                    <Field
                      innerRef={imageInputRef}
                      type="file"
                      className="sr"
                      id="headerImage"
                      name="headerImage"
                      onChange={handleImageChange}
                    />
                  </div>
                  {headerImagePreview && (
                    <div className={styles.formbuttons}>
                      <ButtonCancel onClick={handleImageCancel} />
                      <ButtonConfirm />
                    </div>
                  )}
                  {!previewMode && (
                    <div>
                      <p className="label">Choose Search Thumbnail</p>
                      <p className="font-small">
                        recommended size:
                        <br />
                        640x640
                      </p>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          </div>
        )}
        {detailEditor && !previewMode ? (
          <div className={`column start ${styles.detail} ${styles.form}`}>
            <Formik
              initialValues={{
                story: data && data.story ? data.story : '',
                instagram: data && data.instagram ? data.instagram : '',
                website,
              }}
              validationSchema={ValidationSchema}
              onSubmit={handleDetailSubmit}
            >
              <Form>
                <FormTextField
                  name="story"
                  label={copy.form_story_label}
                  isBox={true}
                  maxLength={500}
                  showCounter={true}
                />
                <FormTextField name="website" label={copy.form_website_label} />
                {showInstagram && (
                  <FormTextField
                    name="instagram"
                    label={copy.form_instagram_label}
                  />
                )}
                <div className={`${styles.formbuttons} ${styles.end}`}>
                  <button
                    type="button"
                    className="button-inline small"
                    onClick={() => setDetailEditor(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="button primary small">
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        ) : (
          <div className={`column center ${styles.detail}`}>
            {isEditable && !detailEditor && (!data || !data.story) ? (
              <p className={styles.hint}>{copy.about_editor_hint}</p>
            ) : null}
            {data && data.story && <p>{data.story}</p>}
            <div className={styles.media}>
              {website && website.length > 0 && (
                <a
                  href={website}
                  className="button primary"
                  target="_blank"
                  rel="norel noreferrer"
                >
                  {copy.about_website_button}
                </a>
              )}
              {showInstagram &&
                data &&
                data.instagram &&
                data.instagram.length > 0 && (
                  <InstagramHandle data={data.instagram} />
                )}
            </div>
            {isEditable && !previewMode && (
              <div className={styles.edit}>
                <EditorIcon onClick={handleDetailEditor} />
              </div>
            )}
          </div>
        )}
        {isEditable &&
          (detailEditor || headerImagePreview) &&
          headerAboutProps.error && (
            <p className="error">{headerAboutProps.error.message}</p>
          )}
      </div>
    </article>
  );
};

export default BusinessAbout;
