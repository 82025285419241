//@flow
import React from 'react';
import Link from 'next/link';
import type { BusinessRecord } from '../../../types/business';
import FixedRatioImage from '../../base/FixedRatioImage';
import styles from './business-recommendation.module.sass';

type Props = {
  business: BusinessRecord;
};

const BusinessRecommendationThumb = ({ business }: Props) => {
  const { headerImage, name, slug } = business.data;

  return (
    <Link href={`/business/${slug}`}>
      <a className={styles.thumb}>
        <FixedRatioImage image={headerImage} ratio="1x1" interactive />
        <p>{name}</p>
      </a>
    </Link>
  );
};

export default BusinessRecommendationThumb;
