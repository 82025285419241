import React from 'react';
import styles from './editor-icon.module.sass';

type Props = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const EditorIcon = ({ onClick }: Props) => {
  return (
    <button className={styles.wrapper} onClick={onClick}>
      <svg
        className={styles.container}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g transform="translate(-16.668 -14.665)">
          <path
            className={styles.icon}
            d="M17.977,68.75l-1.309,4.585,4.585-1.309Z"
            transform="translate(0 -37.081)"
          />
          <path
            className={styles.icon}
            d="M73.653,17.907l-2.176,2.176-3.242-3.242,2.176-2.176Z"
            transform="translate(-35.355)"
          />
          <path
            className={styles.icon}
            d="M24,37.394,36.972,24.426l3.242,3.242L27.246,40.636Z"
            transform="translate(-5.03 -6.692)"
          />
        </g>
      </svg>
    </button>
  );
};

export default EditorIcon;
