//@flow
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Formik } from 'formik';
import { M_BUSINESS_SPECS_EDIT } from '../../../graphql/business';
import { BusinessEditResponse } from '../../../types/business';
import EditorIcon from '../../icons/EditorIcon';
import FormNumberField from '../../form/ui/FormNumberField';
import FormBooleanField from '../../form/ui/FormBooleanField';
import styles from './business-specs.module.sass';
import { cleanDataFromTypes } from '../../../helpers/dataCleaning';

type Props = {
  previewMode: boolean;
  businessId: string;
  businessName: string;
  isEditable: boolean;
  data: any;
  onUpdate: () => void;
  onLayoutUpdate: () => void;
};

const SpecsOwnership = ({
  previewMode,
  businessId,
  businessName,
  isEditable,
  data,
  onUpdate,
  onLayoutUpdate,
}: Props) => {
  const [detailEditor, setDetailEditor] = useState(false);

  const [
    editBusinessOwnership,
    { loading, error },
  ] = useMutation<BusinessEditResponse>(M_BUSINESS_SPECS_EDIT, {
    onCompleted(data: any) {
      if (data.editBusiness) onUpdate();
    },
    onError(error: any) {
      console.log('Error: ', error.message);
    },
  });

  useEffect(() => {
    onLayoutUpdate();
  }, [detailEditor]);

  useEffect(() => {
    if (data) setDetailEditor(false);
  }, [data]);

  const handleDetailEditor = () => {
    setDetailEditor(true);
  };

  const handleOwnershipSubmit = (values) => {
    editBusinessOwnership(
      cleanDataFromTypes({
        variables: {
          id: businessId, //user.business.id,
          ownership: {
            blackOwned: values.ownership.blackOwned,
            blackFounded: values.ownership.blackFounded,
            // blackPartners: values.ownership.blackPartners,
            blackFronted: values.ownership.blackFronted,
            // maintainBlackOwnership: values.ownership.maintainBlackOwnership,
            indigenousOwned: values.ownership.indigenousOwned,
            majorityWomenOwned: values.ownership.majorityWomenOwned,
            // indigenousFounded: values.ownership.indigenousFounded,
            // indigenousPartners: values.ownership.indigenousPartners,
            // indigenousFronted: values.ownership.indigenousFronted,
            // maintainIndigenousOwnership: values.ownership.maintainIndigenousOwnership,
          },
          production: {
            companyProvide: data.production.companyProvide,
            services: data.production.services,
            servicesOther: data.production.servicesOther,
            products: data.production.products,
            ethicallySustainableMade: data.production.ethicallySustainableMade,
            hasFactor: data.production.hasFactor,
            capacity: data.production.capacity,
          },
          retailers: {
            current: data.retailers.current,
            interests: data.retailers.interests,
          },
          finances: {
            wantsInvestorsFunding: data.finances.wantsInvestorsFunding,
          },
        },
      })
    );
  };

  // const renderMaintain = () => {
  //   const items = [];
  //   for (let i = 1; i <= 10; i += 1) {
  //     const item = (
  //       <div
  //         key={`option-${i}`}
  //         className={`${styles.option} ${
  //           data.ownership.maintainBlackOwnership === i ? styles.selected : ''
  //         }`}
  //       >
  //         {i}
  //       </div>
  //     );
  //     items.push(item);
  //   }

  //   return items;
  // };

  let ownershipLine = `${businessName}`;
  const ownershipList = [];
  if (data.ownership.blackOwned) {
    ownershipList.push('Black-Owned');
  }
  if (data.ownership.indigenousOwned) {
    ownershipList.push('Indigenous-Owned');
  }
  if (data.ownership.majorityWomenOwned) {
    ownershipList.push('Majority Women-Owned');
  }
  if (ownershipList.length > 0) {
    ownershipLine += ' is ';
    const lastOne = ownershipList.pop();
    if (ownershipList.length >= 1) {
      ownershipLine += ownershipList.join(', ');
      ownershipLine += ' and ';
    }
    ownershipLine += lastOne + '.';
  }

  const setupInitialValues = (data) => {
    return cleanDataFromTypes({
      ownership: {
        blackOwned:
          data.ownership && data.ownership.blackOwned
            ? data.ownership.blackOwned
            : false,
        blackFounded:
          data.ownership && data.ownership.blackFounded
            ? data.ownership.blackFounded
            : false,
        // blackPartners:
        //   data.ownership && data.ownership.blackPartners
        //     ? data.ownership.blackPartners
        //     : false,
        blackFronted:
          data.ownership && data.ownership.blackFronted
            ? data.ownership.blackFronted
            : false,
        // maintainBlackOwnership:
        //   data.ownership && data.ownership.maintainBlackOwnership
        //     ? data.ownership.maintainBlackOwnership
        //     : 0,
        indigenousOwned:
          data.ownership && data.ownership.indigenousOwned
            ? data.ownership.indigenousOwned
            : false,
        majorityWomenOwned:
          data.ownership && data.ownership.majorityWomenOwned
            ? data.ownership.majorityWomenOwned
            : false,
        // indigenousFounded:
        //   data.ownership && data.ownership.indigenousFounded
        //     ? data.ownership.indigenousFounded
        //     : false,
        // indigenousPartners:
        //   data.ownership && data.ownership.indigenousPartners
        //     ? data.ownership.indigenousPartners
        //     : false,
        // indigenousFronted:
        //   data.ownership && data.ownership.indigenousFronted
        //     ? data.ownership.indigenousFronted
        //     : false,
        // maintainIndigenousOwnership:
        //   data.ownership && data.ownership.maintainIndigenousOwnership
        //     ? data.ownership.maintainIndigenousOwnership
        //     : 0,
      },
    });
  };

  return (
    <div className={styles.info}>
      <h3>Ownership</h3>
      {isEditable && detailEditor === true && !previewMode ? (
        <Formik
          initialValues={setupInitialValues(data)}
          onSubmit={handleOwnershipSubmit}
        >
          {({ values }) => (
            <Form className={styles.form}>
              <div className={styles.grid}>
                <FormBooleanField
                  name="ownership.blackOwned"
                  label="Black Owned"
                  selected={values.ownership.blackOwned}
                />
                <FormBooleanField
                  name="ownership.blackFounded"
                  label="Black Founded"
                  selected={values.ownership.blackFounded}
                />
                {/* <FormBooleanField
                  name="ownership.blackPartners"
                  label="Black Partnered"
                  selected={values.ownership.blackPartners}
                /> */}
                <FormBooleanField
                  name="ownership.blackFronted"
                  label="Black Fronted"
                  selected={values.ownership.blackFronted}
                />
              </div>
              {/*<FormNumberField*/}
              {/*  name="ownership.maintainBlackOwnership"*/}
              {/*  label="Maintain Black Ownership"*/}
              {/*  min={1}*/}
              {/*  max={10}*/}
              {/*/>*/}
              <div className={styles.grid}>
                <FormBooleanField
                  name="ownership.indigenousOwned"
                  label="Indigenous Owned"
                  selected={values.ownership.indigenousOwned}
                />
                {/*<FormBooleanField*/}
                {/*  name="ownership.indigenousFounded"*/}
                {/*  label="Indigenous Founded"*/}
                {/*  selected={values.ownership.indigenousFounded}*/}
                {/*/>*/}
              </div>
              <div className={styles.grid}>
                <FormBooleanField
                  name="ownership.majorityWomenOwned"
                  label="Majority Women-Owned"
                  selected={values.ownership.majorityWomenOwned}
                />
              </div>
              {/*<FormNumberField*/}
              {/*  name="ownership.maintainIndigenousOwnership"*/}
              {/*  label="Maintain Indigenous Ownership"*/}
              {/*  min={1}*/}
              {/*  max={10}*/}
              {/*/>*/}
              <div className={`${styles.formbuttons} ${styles.end}`}>
                <button
                  type="button"
                  className="button-inline small"
                  onClick={() => setDetailEditor(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="button secondary small">
                  Save
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  <p className="error">{error.message}</p>
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <article className="column center">
            <p>{ownershipLine}</p>
          </article>
          {isEditable && detailEditor === false && !previewMode && (
            <article className="center">
              <EditorIcon onClick={handleDetailEditor} />
            </article>
          )}
        </>
      )}
    </div>
  );
};

export default SpecsOwnership;

{
  /* <CheckboxComponent
  label="Black Owned"
  isEditable={false}
  selected={data.ownership.blackOwned}
  center
/>
<CheckboxComponent
  label="Black Founded"
  isEditable={false}
  selected={data.ownership.blackFounded}
  center
/> */
}
{
  /* <CheckboxComponent
  label="Black Partnered"
  isEditable={false}
  selected={data.ownership.blackPartners}
/> */
}
{
  /* <CheckboxComponent
  label="Black Fronted"
  isEditable={false}
  selected={data.ownership.blackFronted}
/> */
}
{
  /* <div className={`column ${styles.layout}`}>
  <h5>Maintain Black Ownership</h5>
  <div className={styles.options}>{renderMaintain()}</div>
</div> */
}
