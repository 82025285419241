import React from 'react';
import { ErrorMessage, Field } from 'formik';
import ErrorMessageComponent from '../ErrorMessageComponent';
import styles from './form-ui.module.sass';

type Props = {
  name: string;
  label: string | React.ReactElement<any>;
  selected?: boolean;
  inline?: boolean;
  between?: boolean;
};

const FormBooleanField = ({ selected, name, label, inline, between }: Props) => {
  return (
    <>
      <label className={`label ${styles.container}`} htmlFor={name}>
        {inline ? (
          <div className={`${styles.inline} ${between ? styles.between : ''} `}>
            {label}
            <div className={`${styles.switch} ${selected ? styles.selected : ''}`}>
              <p>Yes</p>
              <p>No</p>
              <div className={styles.slider} />
            </div>
          </div>
        ) : (
          <>
            {label}
            <div className={`${styles.switch} ${selected ? styles.selected : ''}`}>
              <p>Yes</p>
              <p>No</p>
              <div className={styles.slider} />
            </div>
          </>
        )}
      </label>
      <Field className="sr" type="checkbox" id={name} name={name} />
      <ErrorMessage
        name={name}
        render={(msg) => <ErrorMessageComponent error={msg} />}
      />
    </>
  );
};

export default FormBooleanField;
