import React from 'react';
import styles from './icon.module.sass';

type Props = {
  light?: boolean;
};

const CrossIcon = ({ light }: Props) => {
  return (
    <svg
      className={styles.container}
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <path
        className={`${styles.icon} ${light ? styles.light : ''}`}
        d="M54.3132,49.9997 L99.1072,5.2067 C100.2982,4.0147 100.2982,2.0837 99.1072,0.8927 C97.9152,-0.2973 95.9842,-0.2973 94.7942,0.8927 L50.0002,45.6867 L5.2072,0.8927 C4.0152,-0.2973 2.0842,-0.2973 0.8932,0.8927 C-0.2978,2.0837 -0.2978,4.0147 0.8932,5.2067 L45.6872,49.9997 L0.8932,94.7927 C-0.2978,95.9837 -0.2978,97.9157 0.8932,99.1067 C1.4892,99.7017 2.2692,99.9997 3.0502,99.9997 C3.8302,99.9997 4.6112,99.7017 5.2072,99.1067 L50.0002,54.3127 L94.7942,99.1067 C95.3892,99.7017 96.1702,99.9997 96.9502,99.9997 C97.7302,99.9997 98.5112,99.7017 99.1072,99.1067 C100.2982,97.9157 100.2982,95.9837 99.1072,94.7927 L54.3132,49.9997 Z"
      />
    </svg>
  );
};

export default CrossIcon;
